import { FeeDetail } from '@catalabs/catalyst-api-client';
import { TokenInfo } from '@uniswap/token-lists';

import { formatCurrency } from '~/modules/common';
import { PoolComposition } from '~/modules/pools/components/PoolComposition';
import { SwapCosts } from '~/modules/swap';

export const PoolCreateSummary = ({
  selectedTokens,
  selectedWeights,
  totalLiq,
  feeDetails,
}: {
  selectedTokens: [string, TokenInfo][];
  selectedWeights: [string, number][];
  totalLiq: number;
  feeDetails: FeeDetail[];
}) => {
  return (
    <div className="flex w-full flex-col items-center gap-4">
      <div className="flex h-[36px] w-full items-center justify-between rounded-[25px] bg-white p-6 md:w-[416px]">
        <span className="bg-opacity-15two flex items-center font-semibold font-subheader text-sm uppercase tracking-wide text-grey-900">
          TOTAL LIQUIDITY
        </span>
        <span>{formatCurrency(totalLiq)}</span>
      </div>
      <PoolComposition
        tokens={selectedTokens.map(([_, token]) => token)}
        percentages={selectedWeights.map(([_, weight]) => weight)}
      />

      <SwapCosts feeDetails={feeDetails} />
    </div>
  );
};
