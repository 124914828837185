import { PoolAsset } from '@catalabs/catalyst-api-client';
import { TokenInfo } from '@uniswap/token-lists';

import { ChainDeposit, ChainDepositInfo, ChainWithdraw, CreatePoolType, PoolCreateRequest } from '~/modules/pools';
import { FeeDetail, PendingSwapSummary } from '~/modules/swap/interfaces';

export enum PendingTxnType {
  Swap = 'swap',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Creation = 'creation',
}

export enum PendingTxnStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export interface PendingPoolCreationSummary {
  requests: PoolCreateRequest[];
  feeDetails: FeeDetail[];
  poolFee: number;
  poolType: CreatePoolType;
  totalLiquidity: number;
  progress: number;
  poolAssets: TokenInfo[];
}

export interface PendingDepositSummary {
  requests: ChainDeposit[];
  depositInfo?: ChainDepositInfo;
  partialDeposit?: boolean;
  withLiquiditySwap?: boolean;
  liquiditySwapFinalSwapAmounts?: Map<string, number>;
  liquiditySwapFinalValues?: Map<string, number>;
  poolAssets: PoolAsset[];
  progress: number;
}

export interface PendingWithdrawSummary {
  requests: ChainWithdraw[];
  feeDetails: FeeDetail[];
  priceImpact: number;
  poolAssets: PoolAsset[];
  progress: number;
  withLiquiditySwap?: boolean;
  poolId: number;
}

export interface PendingTxn {
  hash: string;
  type: PendingTxnType;
  swap?: PendingSwapSummary;
  depositDetails?: PendingDepositSummary;
  withdrawalDetails?: PendingWithdrawSummary;
  poolCreationDetails?: PendingPoolCreationSummary;
  status: PendingTxnStatus;
  submittedAt: Date;
}
