export const DEFAULT_CREATE_POOL_SHARE = 100;
export const LIQUIDITY_SWAP_DOCS_URL = 'https://docs.catalyst.exchange/protocol/liquidity-swaps/';

export const POOL_CREATE_FEE_OPTIONS: { value: number; description: string }[] = [
  {
    value: 0.05,
    description: 'Best for stable pairs',
  },
  {
    value: 0.3,
    description: 'Best for most pairs',
  },
  {
    value: 1,
    description: 'Best for exotic pairs',
  },
];
