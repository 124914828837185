import { TokenInfo } from '@uniswap/token-lists';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';

import { formatBalance, TokenBalance } from '~/config';
import { formatCurrency, StoreContext, TokenDisplay } from '~/modules/common';
import { BalanceDisplay } from '~/modules/common/components/BalanceDisplay';
import { Input } from '~/modules/common/components/Input';

import { getAssetKey } from '../utils';

interface TokenAmountInputProps {
  token: TokenInfo;
  amount?: number;
  onChange: (amount: number) => void;
  disabled?: boolean;
  error?: string;
  balance?: TokenBalance;
}

export const TokenAmountInput = observer(
  ({ amount = 0, token, balance, onChange, disabled }: TokenAmountInputProps) => {
    const { catalyst } = useContext(StoreContext);
    const [inputAmount, setInputAmount] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const tokenPrice = catalyst.getPrice(token);
    const tokenValue = tokenPrice * Number(inputAmount);

    const balanceAmount = balance ? formatBalance(balance.amount, balance.decimals) : 0;
    const hasError = amount > balanceAmount;

    const borderClass = hasError ? 'border-red-600' : hasFocus ? 'border-primary-600' : 'border-gray';

    const handleClickMax = () => {
      const amount = balance ? formatBalance(balance.amount, token.decimals) : 0;
      setInputAmount(amount.toString());
      onChange(amount);
    };

    const handleBlur = () => {
      setHasFocus(false);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const amount = e.target.value;
      setInputAmount(amount);
      if (Number(amount)) {
        onChange(Number(amount));
      }
    };

    useEffect(() => {
      if (amount === undefined) {
        return;
      }
      setInputAmount(amount.toString());
    }, [amount]);

    const assetKey = getAssetKey({
      address: token.address,
      chainId: token.chainId.toString(),
    });

    return (
      <div key={assetKey} className="flex">
        <div className={`flex h-[84px] w-full items-center rounded-[12px] border ${borderClass}`}>
          <div className="mx-4 flex w-full items-center gap-2 text-lg">
            <div className="flex min-w-fit flex-col items-start gap-2 text-lg">
              <div className="flex">
                <TokenDisplay token={token.address} chainId={token.chainId.toString()} showChain />
              </div>
              <div className="flex flex-row items-end">
                {balance ? (
                  <BalanceDisplay
                    balance={formatBalance(balance.amount, balance.decimals)}
                    error={hasError}
                    onClick={handleClickMax}
                  />
                ) : (
                  <p className="text-xs text-red-600">Balance not available</p>
                )}
              </div>
            </div>

            <div className="flex w-full flex-col gap-2">
              <Input
                numeric
                className="bg-transparent text-right text-2xl text-gray-800"
                step="0.01"
                lang="en"
                onWheel={(e) => e.currentTarget.blur()}
                onChange={handleOnChange}
                placeholder="0.00"
                value={inputAmount}
                disabled={disabled}
                onFocus={() => setHasFocus(true)}
                onBlur={handleBlur}
              />
              <span className="mb-1 pr-1 text-right text-xs font-normal leading-[10px] text-grey-500">
                {formatCurrency(tokenValue)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default TokenAmountInput;
