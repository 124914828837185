import { CatalystNetwork } from '~/config';

const numericRegex = /^\d*\.?\d*$/;

/**
 * Shorten an EVM address to display on the interface.
 * @param address Address to shorten
 * @returns Representation of the first and last parts of the address
 */
export function shortenAddress(address: string): string {
  return address
    .slice(0, 6)
    .concat('...')
    .concat(address.slice(address.length - 4));
}

/**
 * Format a balance string for consistency across the application.
 * @param value Balance numeric value
 * @returns Balance strin display
 */
export function formatTokenBalance(value: number, readable = false, decimals = 2): string {
  const decimalsToUse = value > 0 && value < 1 ? 5 : decimals;
  if (value === 0) {
    return '0.'.concat('0'.repeat(decimalsToUse));
  }
  let balanceString;
  // use three decimals for rounding reasons
  if (readable) {
    balanceString = value.toLocaleString(undefined, {
      maximumFractionDigits: decimalsToUse + 1,
    });
  } else {
    balanceString = value.toFixed(decimalsToUse + 1);
  }
  if (balanceString === '0') {
    return '0'.concat('.').concat('0'.repeat(decimalsToUse));
  }
  const decimalCount = balanceString.includes('.') ? balanceString.split('.')[1].length : 0;
  return decimalCount === decimalsToUse
    ? balanceString
    : balanceString.slice(0, balanceString.length - (decimalCount - decimalsToUse));
}

/**
 * Get the animation prefix required for showing a flipping chevron
 * @param active Indication if the chevron is 'active'
 * @returns The proper animation state to display the action
 */
export function getAnimationState(active: boolean) {
  if (active) {
    return 'animate-close';
  }
  return 'animate-flip';
}

export function displayDurationMs(duration: number): string {
  return new Date(duration).toISOString().slice(11, 19);
}

export function isValidNumeric(value: string): boolean {
  if (isNaN(Number(value))) {
    return false;
  }
  return numericRegex.test(value.toString());
}

export function formatValue(value: number, maxDecimals = 18): string {
  // round down value to maxDecimals
  // The native number formatter is better, but waiting for the next release to use it
  // https://github.com/microsoft/TypeScript/issues/57475
  // @TODO: update to use the native number formatter when it's released

  // If the value is in scientific notation, we need to handle it differently
  if (value.toString().includes('e')) {
    const decimalsPart = value?.toString()?.split('.')?.[1] || '';
    const eDecimals = Number(decimalsPart?.split('e-')?.[1]) || 0;
    const countOfDecimals = decimalsPart.length + eDecimals;
    // Ensure the number of decimals does not exceed maxDecimals
    const fixedDecimals = Math.min(countOfDecimals, maxDecimals);

    return Number(value).toFixed(fixedDecimals);
  }

  const roundedValue = Math.floor(value * 10 ** maxDecimals) / 10 ** maxDecimals;
  return roundedValue.toString();
}

export function formatCurrency(value: number, maxDecimals = 2): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: value > 0 && value < 1 ? 5 : maxDecimals,
  });

  if (isNaN(value)) {
    return formatter.format(0);
  }
  return formatter.format(value);
}

export function formatPercentage(value: number, decimals = 2): string {
  if (isNaN(value)) {
    return `${Number(0).toFixed(decimals)}%`;
  }
  return `${value.toFixed(decimals)}%`;
}

export function displayChainName(chainId: string | number): string {
  const network = CatalystNetwork.getCatalystNetwork(chainId.toString());
  return network.config.name;
}

/**
 * Formatting date to DD/MM/YY
 * @param date Date or string of a date
 * @returns Formatted date string in DD/MM/YY format
 */
export const formatDate = (date: string | Date): string => {
  const safeDate = new Date(date);
  const day = safeDate.getDate().toString().padStart(2, '0');
  const month = (safeDate.getMonth() + 1).toString().padStart(2, '0');
  const year = safeDate.getFullYear().toString().slice(-2);
  return `${day}/${month}/${year}`;
};

/**
 * Formats hash to 0x123456...
 * @param hash
 * @returns Formatted hash string
 */
export function formatHash(hash: string): string {
  return `${hash.slice(0, 8)}...${hash.slice(-8)}`;
}
