import { LiquiditySwap, poll, SwapState } from '@catalabs/catalyst-api-client';
import {
  CatalystSDK,
  depositWithGasViaPermit,
  EVM_ROUTER_ADDRESS,
  PERMIT2_ADDRESS,
  PermitBatchData,
  prepareDepositWithLiquiditySwaps,
} from '@catalabs/catalyst-sdk';
import { ethers } from 'ethers';
import localforage from 'localforage';
import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import { CatalystNetwork, delay } from '~/config';
import { captureException, DepositRequest } from '~/modules/common';
import { Store } from '~/modules/common/store/interfaces';
import { RootStore } from '~/modules/common/store/root-store';
import { ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION, PendingTxn, PendingTxnStatus, PendingTxnType } from '~/modules/lobby';
import { SwapStore } from '~/modules/swap';

import { DepositError, PoolActionState, PoolActionType, PoolApprovalState, PoolInteractionStep } from '../enums';
import {
  ChainDeposit,
  ChainDepositInfo,
  DepositWithLiquiditySwapInputs,
  DepositWithLiquiditySwapRequest,
} from '../interfaces';
import { sortAndfilterOutZeroDepositRequests } from '../utils/deposit.utils';

export class PoolDepositStore implements Store {
  poolAction = PoolActionType.Deposit;

  // Pool Deposit
  poolDepositStep?: PoolInteractionStep = undefined;
  poolDepositRequest?: ChainDeposit[] = undefined;
  imbalancedDeposits = false;
  depositSlippage = SwapStore.DEFAULT_SLIPPAGE;
  depositInfo?: ChainDepositInfo = undefined;
  poolDepositError?: DepositError = undefined;
  partialDeposit = false;
  runningDeposit = false;
  address?: string;

  withLiquiditySwap = false;
  txInputs?: DepositWithLiquiditySwapInputs = undefined;
  liquiditySwapFinalAmounts?: Map<string, number> = undefined;
  liquiditySwapFinalValues?: Map<string, number> = undefined;

  constructor(private store: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: `PoolDepositStore`,
      properties: [
        'depositInfo',
        {
          key: 'poolDepositRequest',
          serialize: (value: ChainDeposit[] | undefined) => {
            return value;
          },
          deserialize: (value: ChainDeposit[]) => {
            const requests = value.map((r: ChainDeposit) => ({
              ...r,
              request: r.request.map((d) => {
                const amount = BigInt(d.amount);
                return {
                  ...d,
                  amount,
                };
              }),
            }));
            return requests;
          },
        },
        'poolDepositRequest',
        'poolDepositError',
        'imbalancedDeposits',
        'partialDeposit',
        'address',
        'withLiquiditySwap',
        {
          key: 'txInputs',
          serialize: (value: DepositWithLiquiditySwapInputs | undefined) => {
            return value;
          },
          deserialize: (value: DepositWithLiquiditySwapInputs) => {
            value.userDeposits = value.userDeposits.map((d) => d.map((a) => BigInt(a)));
            value.priceOfDeliveryGas = value.priceOfDeliveryGas.map((d) => d.map((a) => BigInt(a)));
            value.priceOfAckGas = value.priceOfAckGas.map((a) => BigInt(a));
            value.messageVerifyGasCosts = value.messageVerifyGasCosts.map((d) => d.map((a) => BigInt(a)));
            value.vaults = value.vaults.map((v) => ({
              balances: v.balances.map((b) => BigInt(b)),
              escrowedTokens: v.escrowedTokens.map((e) => BigInt(e)),
              weights: v.weights.map((w) => BigInt(w)),
              totalSupply: BigInt(v.totalSupply),
              escrowedVaultTokens: BigInt(v.escrowedVaultTokens),
              vaultFee: BigInt(v.vaultFee),
              amplification: BigInt(v.amplification),
              unitTracker: BigInt(v.unitTracker),
            }));
            return value;
          },
        },
        'liquiditySwapFinalAmounts',
        'liquiditySwapFinalValues',
      ],
      storage: localforage,
      stringify: false,
    });
  }

  get showPendingDeposit(): boolean {
    return this.partialDeposit && !this.runningDeposit && this.address === this.store.wallet.address;
  }

  /**
   *
   * @param request
   * @returns
   */
  async deposit(request: DepositRequest): Promise<void> {
    // get the user's address
    const { address } = this.store.wallet;
    runInAction(() => {
      this.address = address;
      this.poolDepositError = undefined;
      this.withLiquiditySwap = false;
    });
    // get the source network of the swap
    const { sourceNetwork } = this.store.swap;
    // check if every request has a zero amount
    const hasNoDeposits = request.every((r) => BigInt(r.amount) == 0n);

    // if the address is not set or there are no deposits, return
    if (!address || hasNoDeposits) {
      return;
    }

    const sortedRequests = sortAndfilterOutZeroDepositRequests({
      request,
      pool: this.store.pool.pool,
      sourceNetwork,
    });

    runInAction(() => {
      this.poolDepositRequest = sortedRequests;
      this.poolDepositStep = PoolInteractionStep.Approval;
    });

    await this.executeDepositRequests();
  }

  async resumeDeposit(): Promise<void> {
    if (this.withLiquiditySwap) {
      return this.resumeDepositWithLiquiditySwap();
    } else {
      return this.executeDepositRequests();
    }
  }

  async depositWithLiquiditySwap({ requests, txInputs }: DepositWithLiquiditySwapRequest): Promise<void> {
    if (this.runningDeposit) {
      return;
    }
    runInAction(() => {
      this.withLiquiditySwap = true;
      this.poolDepositError = undefined;
      this.runningDeposit = true;
    });
    // get the user's address
    const { address } = this.store.wallet;
    // check if every request has a zero amount
    const hasNoDeposits = requests.every((r) => r.amount === 0n);

    // if the address is not set or there are no deposits, return
    if (!address || hasNoDeposits) {
      return;
    }

    // if the source network is not set, set it to the first request's chainId
    const txChainIds = txInputs.chains.map((c) => c.chainId);

    // this operation creates request chunks by chainId
    // create a temp store for requests
    const requestMap = new Map<string, DepositRequest>();
    // for each request
    requests.forEach((r) => {
      // get a chunk of the request from the temp store based on the chainId
      const requestChunk = requestMap.get(r.chainId);
      if (!requestChunk) {
        // if there's no chunk, create one
        requestMap.set(r.chainId, [r]);
      } else {
        requestChunk.push(r);
      }
    });

    // sort the request chunks by txInputs.chains chainId order
    const sortedRequests: ChainDeposit[] = await Promise.all(
      Array.from(requestMap.entries())
        .sort((a, b) => {
          return txChainIds.indexOf(a[0]) - txChainIds.indexOf(b[0]);
        })
        .filter((e) => e[1].some((r) => r.amount > 0n))
        .map(async (e) => {
          const [chainId, request] = e;
          const [{ vault }] = request;
          const tokenInfo = await this.store.catalyst.getToken(chainId, vault);
          return {
            chainId,
            tokenInfo,
            chainSwap: PoolActionState.Inactive,
            approval: PoolActionState.Inactive,
            deposit: PoolActionState.Inactive,
            request,
            poolId: this.store.pool.pool?.id,
            liquiditySwap: true,
          };
        }),
    );

    try {
      // generate the execution instructions
      const {
        userDeposits,
        chains,
        vaults,
        vaultAddresses,
        assetsAddresses,
        userAddresses,
        refundGasTo,
        messageVerifyGasCosts,
        priceOfDeliveryGas,
        priceOfAckGas,
        feeData,
        wrapGasValues,
      } = txInputs;

      runInAction(() => {
        this.poolDepositRequest = sortedRequests;
        this.poolDepositStep = PoolInteractionStep.Approval;
        this.txInputs = txInputs;
      });

      const responses = prepareDepositWithLiquiditySwaps(
        userDeposits,
        chains,
        vaults,
        vaultAddresses,
        assetsAddresses,
        userAddresses,
        messageVerifyGasCosts,
        priceOfDeliveryGas,
        priceOfAckGas,
        refundGasTo,
        undefined,
        wrapGasValues,
      );

      let requestIndex = 0;
      for (const depositRequest of sortedRequests) {
        if (
          depositRequest.deposit === PoolActionState.Completed ||
          depositRequest.deposit === PoolActionState.Confirmed
        ) {
          requestIndex += 1;
          continue;
        }
        // get the current network
        const { sourceNetwork } = this.store.swap;
        const baseRequest = depositRequest.request[0];
        const updatedRequest = sortedRequests.slice();
        if (baseRequest.chainId !== sourceNetwork) {
          sortedRequests[requestIndex].chainSwap = PoolActionState.Pending;
          runInAction(() => {
            this.poolDepositRequest = sortedRequests;
          });
          const connected = await this.store.wallet.connectNetwork(baseRequest.chainId);
          if (connected) {
            updatedRequest[requestIndex].chainSwap = PoolActionState.Confirmed;
            updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
            runInAction(() => {
              this.store.swap.sourceAsset = undefined;
              this.store.swap.sourceNetwork = baseRequest.chainId;
              this.poolDepositRequest = updatedRequest;
              this.poolDepositError = undefined;
            });
          } else {
            console.error('Error changing networks, persist depsoit attempt for future resumption');
            updatedRequest[requestIndex].chainSwap = PoolActionState.Inactive;
            runInAction(() => {
              this.poolDepositRequest = updatedRequest;
              this.store.swap.sourceAsset = undefined;
              this.store.swap.sourceNetwork = undefined;
              this.runningDeposit = false;
              this.poolDepositError = DepositError.REJECTED;
            });
            return;
          }
          await delay(1000);
        } else {
          updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
          });
        }
        await delay(1000);
        const { sdk } = CatalystNetwork.getCatalystNetwork(baseRequest.chainId);

        // TODO: move this into one area on how to get and gereate fee data input vs. having it three times
        const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = feeData[baseRequest.chainId];
        const supportsEip1559 = maxPriorityFeePerGas !== null;
        const gasOptions = {
          ...(!supportsEip1559 && { gasPrice: BigInt(gasPrice) }),
          ...(supportsEip1559 && {
            maxFeePerGas: BigInt(maxFeePerGas),
            maxPriorityFeePerGas: BigInt(maxPriorityFeePerGas),
          }),
        };

        try {
          const permitBatchData: PermitBatchData | undefined = await this.handlePermitForDepositRequest(
            sdk,
            address,
            sortedRequests,
            requestIndex,
            depositRequest,
          );

          updatedRequest[requestIndex].deposit = PoolActionState.Pending;
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
          });

          // TODO: utilize minOut, currently failing due to transfer from failures
          // const depositEstimate = await sdk.estimateDeposit(baseRequest.vault, sortedDeposits.map((d) => d.amount));
          // const minOut  = depositEstimate.mul(this.depositSlippage * 100).div(100);

          const response = responses.find(
            (_, i) => chains[i].chainId.toString() === sortedRequests[requestIndex].chainId,
          );

          if (!response) {
            throw new Error('Invalid routerArgs, gasUsage, or transferDetails');
          }

          const requestsThatUseGasTokenIndex = updatedRequest[requestIndex].request
            .filter((r) => r.useGasToken)
            .map((r) => r.index);
          const useGasToken = updatedRequest[requestIndex].request.some((r) => r.useGasToken);
          const { routerArgs, gasUsage, transferDetails } = response;

          const transferDetailsForWrappedAssets = transferDetails.filter(
            (_, i) => !requestsThatUseGasTokenIndex.includes(i),
          );

          const { executionInstructions } = routerArgs.transferWithPermitForDepositWithLiquiditySwaps(
            transferDetailsForWrappedAssets,
            address,
            gasUsage,
            permitBatchData,
          );

          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
          });

          let gasAmount = BigInt(0);

          if (useGasToken) {
            const gasNeededForDeposit = updatedRequest[requestIndex].request.reduce((acc, r) => acc + r.amount, 0n);
            gasAmount = gasAmount + gasNeededForDeposit;
          }

          gasAmount = gasAmount + (executionInstructions.gas.estimatedRoutingPayment * 12n) / 10n;

          const { hash, wait } = await sdk.sendAssetWithRouter(executionInstructions, gasAmount, gasOptions);

          updatedRequest[requestIndex].hash = hash;
          updatedRequest[requestIndex].deposit = PoolActionState.Confirmed;
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
            this.partialDeposit = requestIndex === sortedRequests.length - 1 ? false : true;
          });
          await wait();
        } catch (e) {
          runInAction(() => {
            updatedRequest[requestIndex].hash = undefined;
            updatedRequest[requestIndex].deposit = PoolActionState.Inactive;
          });
          return this.handleTxError(e);
        }

        runInAction(() => {
          this.poolDepositRequest = updatedRequest;
        });

        requestIndex += 1;
      }
      this.addTxToLobby();
      const hash = this.poolDepositRequest?.[0].hash;
      const chainId = this.poolDepositRequest?.[0].chainId;
      if (hash && chainId) {
        this.checkForLiquiditySwapDepositUpdates(chainId, hash);
      }
      runInAction(() => {
        this.txInputs = undefined;
        this.runningDeposit = false;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.runningDeposit = false;
      });
      this.handleTxError(error);
    }
  }

  private async resumeDepositWithLiquiditySwap(): Promise<void> {
    if (this.runningDeposit) {
      return;
    }
    // get the user's address
    const { address } = this.store.wallet;
    runInAction(() => {
      this.address = address;
      this.poolDepositError = undefined;
      this.runningDeposit = true;
    });

    // get the requests from the store
    const sortedRequests = this.poolDepositRequest ?? [];
    // check if every request has a zero amount
    const hasNoDeposits = sortedRequests.every((r) => r.request.every((r) => r.amount === 0n));

    // if the address is not set or there are no deposits, return
    if (!address || hasNoDeposits || !this.txInputs) {
      return;
    }

    try {
      const {
        userDeposits,
        chains,
        vaults,
        vaultAddresses,
        assetsAddresses,
        userAddresses,
        refundGasTo,
        messageVerifyGasCosts,
        priceOfDeliveryGas,
        priceOfAckGas,
      } = this.txInputs;

      const gasData = await this.store.catalyst.getGasFeeData(chains.map((chain) => chain.chainId.toString()));

      const responses = prepareDepositWithLiquiditySwaps(
        userDeposits,
        chains,
        vaults,
        vaultAddresses,
        assetsAddresses,
        userAddresses,
        messageVerifyGasCosts,
        priceOfDeliveryGas,
        priceOfAckGas,
        refundGasTo,
      );

      let requestIndex = 0;
      for (const depositRequest of sortedRequests) {
        if (
          depositRequest.deposit === PoolActionState.Completed ||
          depositRequest.deposit === PoolActionState.Confirmed
        ) {
          requestIndex += 1;
          continue;
        }
        // get the current network
        const { sourceNetwork } = this.store.swap;
        const baseRequest = depositRequest.request[0];
        const updatedRequest = sortedRequests.slice();
        if (baseRequest.chainId !== sourceNetwork) {
          sortedRequests[requestIndex].chainSwap = PoolActionState.Pending;
          runInAction(() => {
            this.poolDepositRequest = sortedRequests;
          });
          const connected = await this.store.wallet.connectNetwork(baseRequest.chainId);
          if (connected) {
            updatedRequest[requestIndex].chainSwap = PoolActionState.Confirmed;
            updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
            runInAction(() => {
              this.store.swap.sourceAsset = undefined;
              this.store.swap.sourceNetwork = baseRequest.chainId;
              this.poolDepositRequest = updatedRequest;
              this.poolDepositError = undefined;
            });
          } else {
            console.error('Error changing networks, persist depsoit attempt for future resumption');
            updatedRequest[requestIndex].chainSwap = PoolActionState.Inactive;
            runInAction(() => {
              this.poolDepositRequest = updatedRequest;
              this.store.swap.sourceAsset = undefined;
              this.store.swap.sourceNetwork = undefined;
              this.runningDeposit = false;
              this.poolDepositError = DepositError.REJECTED;
            });
          }
          await delay(1000);
        } else {
          updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
          });
        }
        await delay(1000);
        const { sdk } = CatalystNetwork.getCatalystNetwork(baseRequest.chainId);

        // TODO: move this into one area on how to get and gereate fee data input vs. having it three times
        const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = gasData[baseRequest.chainId];
        const supportsEip1559 = maxPriorityFeePerGas !== null;
        const gasOptions = {
          ...(!supportsEip1559 && { gasPrice: BigInt(gasPrice) }),
          ...(supportsEip1559 && {
            maxFeePerGas: BigInt(maxFeePerGas),
            maxPriorityFeePerGas: BigInt(maxPriorityFeePerGas),
          }),
        };

        try {
          const permitBatchData = await this.handlePermitForDepositRequest(
            sdk,
            address,
            sortedRequests,
            requestIndex,
            depositRequest,
          );

          updatedRequest[requestIndex].deposit = PoolActionState.Pending;
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
          });

          // TODO: utilize minOut, currently failing due to transfer from failures
          // const depositEstimate = await sdk.estimateDeposit(baseRequest.vault, sortedDeposits.map((d) => d.amount));
          // const minOut  = depositEstimate.mul(this.depositSlippage * 100).div(100);

          const response = responses.find(
            (_, i) => chains[i].chainId.toString() === sortedRequests[requestIndex].chainId,
          );

          if (!response) {
            throw new Error('Invalid routerArgs, gasUsage, or transferDetails');
          }

          const requestsThatUseGasTokenIndex = updatedRequest[requestIndex].request
            .filter((r) => r.useGasToken)
            .map((r) => r.index);
          const useGasToken = updatedRequest[requestIndex].request.some((r) => r.useGasToken);
          const { routerArgs, gasUsage, transferDetails } = response;

          const transferDetailsForWrappedAssets = transferDetails.filter(
            (_, i) => !requestsThatUseGasTokenIndex.includes(i),
          );
          const { executionInstructions } = routerArgs.transferWithPermitForDepositWithLiquiditySwaps(
            transferDetailsForWrappedAssets,
            address,
            gasUsage,
            permitBatchData,
          );

          let totalGasAmount = (executionInstructions.gas.estimatedRoutingPayment * 12n) / 10n;

          if (useGasToken) {
            const gasNeededForDeposit = updatedRequest[requestIndex].request.reduce((acc, r) => acc + r.amount, 0n);
            totalGasAmount = totalGasAmount + gasNeededForDeposit;
          }

          const gasAmount = (executionInstructions.gas.estimatedRoutingPayment * 12n) / 10n;
          const { hash, wait } = await sdk.sendAssetWithRouter(executionInstructions, gasAmount, gasOptions);

          updatedRequest[requestIndex].hash = hash;
          updatedRequest[requestIndex].deposit = PoolActionState.Confirmed;
          await wait();
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
            this.partialDeposit = requestIndex === sortedRequests.length - 1 ? false : true;
          });
        } catch (e) {
          return this.handleTxError(e);
        }

        runInAction(() => {
          this.poolDepositRequest = updatedRequest;
        });

        requestIndex += 1;
      }
      this.addTxToLobby();
      const hash = this.poolDepositRequest?.[0].hash;
      const chainId = this.poolDepositRequest?.[0].chainId;
      if (hash && chainId) {
        this.checkForLiquiditySwapDepositUpdates(chainId, hash);
      }
      runInAction(() => {
        this.txInputs = undefined;
        this.runningDeposit = false;
      });
    } catch (error) {
      runInAction(() => {
        this.runningDeposit = false;
      });
      return this.handleTxError(error);
    }
  }

  private async executeDepositRequests(): Promise<void> {
    if (this.runningDeposit) {
      return;
    }
    // get the user's address
    const { address } = this.store.wallet;
    runInAction(() => {
      this.address = address;
      this.poolDepositError = undefined;
      this.runningDeposit = true;
    });
    // get the source network of the swap
    const { sourceNetwork } = this.store.swap;
    // get the requests from the store
    const sortedRequests = this.poolDepositRequest ?? [];
    // check if every request has a zero amount
    const hasNoDeposits = sortedRequests.every((r) => r.request.every((r) => r.amount === 0n));

    // if the address is not set or there are no deposits, return
    if (!address || hasNoDeposits) {
      return;
    }

    const gasData = await this.store.catalyst.getGasFeeData(sortedRequests.map((chainDeposit) => chainDeposit.chainId));

    let requestIndex = 0;

    for (const depositRequest of sortedRequests) {
      if (
        depositRequest.deposit === PoolActionState.Completed ||
        depositRequest.deposit === PoolActionState.Confirmed
      ) {
        requestIndex += 1;
        continue;
      }
      const baseRequest = depositRequest.request[0];
      const updatedRequest = sortedRequests.slice();
      if (baseRequest.chainId !== sourceNetwork) {
        sortedRequests[requestIndex].chainSwap = PoolActionState.Pending;
        runInAction(() => {
          this.poolDepositRequest = sortedRequests;
        });
        const connected = await this.store.wallet.connectNetwork(baseRequest.chainId);
        if (connected) {
          updatedRequest[requestIndex].chainSwap = PoolActionState.Confirmed;
          updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
          runInAction(() => {
            this.store.swap.sourceAsset = undefined;
            this.store.swap.sourceNetwork = baseRequest.chainId;
            this.poolDepositRequest = updatedRequest;
            this.poolDepositError = undefined;
          });
        } else {
          console.error('Error changing networks, persist depsoit attempt for future resumption');
          updatedRequest[requestIndex].chainSwap = PoolActionState.Inactive;
          runInAction(() => {
            this.poolDepositRequest = updatedRequest;
            this.store.swap.sourceAsset = undefined;
            this.store.swap.sourceNetwork = undefined;
            this.runningDeposit = false;
            this.poolDepositError = DepositError.REJECTED;
          });
          return;
        }

        await delay(1000);
      } else {
        updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
        runInAction(() => {
          this.poolDepositRequest = updatedRequest;
        });
      }
      await delay(1000);
      const { sdk } = CatalystNetwork.getCatalystNetwork(baseRequest.chainId);

      // TODO: move this into one area on how to get and generate fee data input vs. having it three times
      const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = gasData[baseRequest.chainId];
      const supportsEip1559 = maxPriorityFeePerGas !== null || maxPriorityFeePerGas !== 0n;
      const gasOptions = {
        ...(!supportsEip1559 && { gasPrice: BigInt(gasPrice) }),
        ...(supportsEip1559 && {
          maxFeePerGas: BigInt(maxFeePerGas),
          maxPriorityFeePerGas: BigInt(maxPriorityFeePerGas),
        }),
      };

      let permitBatchData: PermitBatchData | undefined;
      try {
        permitBatchData = await this.handlePermitForDepositRequest(
          sdk,
          address,
          sortedRequests,
          requestIndex,
          depositRequest,
        );
      } catch (e) {
        return this.handleTxError(e);
      }

      updatedRequest[requestIndex].deposit = PoolActionState.Pending;
      runInAction(() => {
        this.poolDepositRequest = updatedRequest;
      });

      const sortedDeposits = depositRequest.request.sort((a, b) => a.index - b.index);
      const tokens = sortedDeposits.map((r) => r.address);
      const nonZeroDeposits = sortedDeposits.filter((r) => BigInt(r.amount) > 0n);
      const notGasTokenDeposit = nonZeroDeposits.filter((r) => !r.useGasToken);
      const transferFroms: [string[], bigint[]] = [
        notGasTokenDeposit.map((d) => d.address),
        notGasTokenDeposit.map((d) => BigInt(d.amount)),
      ];

      // TODO: utilize minOut, currently failing due to transfer from failures
      // const depositEstimate = await sdk.estimateDeposit(baseRequest.vault, sortedDeposits.map((d) => d.amount));
      // const minOut  = depositEstimate.mul(this.depositSlippage * 100).div(100);

      try {
        const [commands, inputs] = depositWithGasViaPermit(
          baseRequest.vault,
          tokens,
          BigInt(0),
          transferFroms,
          address,
          permitBatchData, // Permit batch data for tokens to be deposited
        );
        const totalGasAmount = updatedRequest[requestIndex].request.reduce(
          (acc, r) => acc + (r.useGasToken ? r.amount : 0n),
          0n,
        );
        const { hash, wait } = await sdk.sendAssetWithRouter({ commands, inputs }, totalGasAmount, gasOptions);

        updatedRequest[requestIndex].hash = hash;
        updatedRequest[requestIndex].deposit = PoolActionState.Confirmed;
        runInAction(() => {
          this.poolDepositRequest = updatedRequest;
          this.partialDeposit = requestIndex === sortedRequests.length - 1 ? false : true;
        });
        await wait();
      } catch (e) {
        return this.handleTxError(e);
      }

      updatedRequest[requestIndex].deposit = PoolActionState.Completed;
      runInAction(() => {
        this.poolDepositRequest = updatedRequest;
      });

      requestIndex += 1;
    }
    runInAction(() => {
      this.runningDeposit = false;
    });
  }

  /**
   * Handles the process of obtaining a permit for deposit requests.
   *
   * This function performs the following steps:
   * 1. Checks the allowance for each asset in the deposit requests.
   * 2. If the allowance is insufficient, it approves the Permit2 address for the token to be deposited.
   * 3. Updates the deposit request state to indicate the pending permit signature.
   * 4. Generates the permit batch data for the tokens to be deposited.
   * 5. Updates the deposit request state to indicate the confirmed and completed approvals.
   *
   * @param sdk - The Catalyst SDK instance.
   * @param owner - The address of the owner.
   * @param depositRequests - The array of chain deposit requests.
   * @param depositRequestIndex - The index of the current deposit request.
   * @param depositRequest - The current deposit request.
   * @returns The permit batch data or `undefined` if no permit data is generated.
   */
  private async handlePermitForDepositRequest(
    sdk: CatalystSDK,
    owner: string,
    depositRequests: ChainDeposit[],
    depositRequestIndex: number,
    depositRequest: ChainDeposit,
    gasOptions?: {
      maxFeePerGas?: bigint | undefined;
      maxPriorityFeePerGas?: bigint | undefined;
      gasPrice?: bigint | undefined;
    },
  ): Promise<PermitBatchData | undefined> {
    let permitBatchData: PermitBatchData | undefined;
    const assetsArray: { token: string; amount?: bigint }[] = [];

    const sortedAssetRequests = depositRequest.request.slice().sort((a, b) => a.address.localeCompare(b.address));

    let assetIndex = 0;
    for (const assetRequest of sortedAssetRequests) {
      // Check allowance to permit2 address for token to be deposited.
      if (assetRequest.useGasToken) {
        const updatedDepositRequest = depositRequests.slice();
        updatedDepositRequest[depositRequestIndex].request[assetIndex].approval = PoolApprovalState.Completed;
        runInAction(() => {
          this.poolDepositRequest = updatedDepositRequest;
        });
        assetIndex += 1;
        continue;
      }
      const permit2Allowance = await sdk.checkAllowance(assetRequest.address, owner, PERMIT2_ADDRESS);
      if (permit2Allowance < assetRequest.amount) {
        // Update the request to indicate approval to permit2 address on UI
        const updatedDepositRequest = depositRequests.slice();
        updatedDepositRequest[depositRequestIndex].request[assetIndex].approval =
          PoolApprovalState.PendingApprovalToPermit2;
        runInAction(() => {
          this.poolDepositRequest = updatedDepositRequest;
        });
        // Approve permit2 address for token to be deposited with max uint256 value
        const tx = await sdk.increaseAllowance(
          assetRequest.address,
          PERMIT2_ADDRESS,
          BigInt(ethers.MaxUint256.toString()),
          gasOptions,
        );
        await tx.wait();
      }

      const permittedAmount = await sdk.checkPermitAmount(assetRequest.address, owner, EVM_ROUTER_ADDRESS);

      const updatedDepositRequest = depositRequests.slice();
      if (permittedAmount < assetRequest.amount) {
        // Update the request to indicate permit signing on UI
        updatedDepositRequest[depositRequestIndex].request[assetIndex].approval =
          PoolApprovalState.PendingPermitSignature;
        runInAction(() => {
          this.poolDepositRequest = updatedDepositRequest;
        });
        // Add token to assetsArray, will be used later to generate permit batch data
        assetsArray.push({ token: assetRequest.address });
      } else {
        updatedDepositRequest[depositRequestIndex].request[assetIndex].approval = PoolApprovalState.Completed;
        runInAction(() => {
          this.poolDepositRequest = updatedDepositRequest;
        });
      }

      assetIndex += 1;
    }

    if (assetsArray.length > 0) {
      // Generate permit batch data for tokens to be deposited
      permitBatchData = await sdk.generatePermitBatchData(assetsArray, EVM_ROUTER_ADDRESS);

      // Fix for ledger devices
      const sixtyFourthBit = permitBatchData.signature.slice(2 + 64 * 2, 2 + 64 * 2 + 2);
      const parsedSixtyFourthBit = parseInt(sixtyFourthBit, 16);
      if (parsedSixtyFourthBit < 27) {
        permitBatchData.signature =
          permitBatchData.signature.slice(0, 2 + 64 * 2) +
          (parsedSixtyFourthBit + 27).toString(16) +
          permitBatchData.signature.slice(2 + 64 * 2 + 2);
      }
    }

    assetIndex = 0;
    for (const assetRequest of sortedAssetRequests) {
      if (assetRequest.approval === PoolApprovalState.Completed) {
        assetIndex += 1;
        continue;
      }

      const updatedDepositRequest = depositRequests.slice();
      if (assetRequest.approval === PoolApprovalState.PendingPermitSignature) {
        updatedDepositRequest[depositRequestIndex].request[assetIndex].approval = PoolApprovalState.Confirmed;
        runInAction(() => {
          this.poolDepositRequest = updatedDepositRequest;
        });

        await delay(1000);
        updatedDepositRequest[depositRequestIndex].request[assetIndex].approval = PoolApprovalState.Completed;
        runInAction(() => {
          this.poolDepositRequest = updatedDepositRequest;
        });
      }

      assetIndex += 1;
    }

    return permitBatchData;
  }

  private async addTxToLobby(): Promise<void> {
    if (this.poolDepositRequest) {
      const hash = this.poolDepositRequest[0]?.hash;
      if (hash) {
        const pendingTxn: PendingTxn = {
          hash,
          type: PendingTxnType.Deposit,
          status: PendingTxnStatus.Pending,
          submittedAt: new Date(),
          depositDetails: {
            requests: this.poolDepositRequest,
            depositInfo: this.depositInfo,
            poolAssets: this.store.pool.pool?.assets || [],
            liquiditySwapFinalSwapAmounts: this.liquiditySwapFinalAmounts,
            liquiditySwapFinalValues: this.liquiditySwapFinalValues,
            partialDeposit: this.partialDeposit,
            withLiquiditySwap: this.withLiquiditySwap,
            progress: 0,
          },
        };
        this.store.lobby.addPendingTxn(hash, pendingTxn);
      }
    }
  }

  private checkForLiquiditySwapDepositUpdates(chainId: string, hash: string): void {
    poll(
      () => this.store.client.swaps.getLiquiditySwap(chainId, hash),
      (result: LiquiditySwap | null) => {
        if (this.poolDepositRequest) {
          const updatedRequest = this.poolDepositRequest.slice();
          const requestHash = updatedRequest[0]?.hash;

          if (requestHash !== hash || !this.runningDeposit) {
            return false;
          }
          if (!result) {
            return true;
          }

          const { state, fromHash } = result;
          if (fromHash !== hash) {
            return false;
          }
          switch (state) {
            case SwapState.CONFIRMED:
            case SwapState.COMPLETED:
              updatedRequest.forEach((r) => {
                r.deposit = PoolActionState.Completed;
              });
              runInAction(() => {
                this.runningDeposit = false;
                this.poolDepositRequest = updatedRequest;
              });
              this.store.lobby.removeTxnFromLobby(hash);
              return false;
            case SwapState.REVERTED:
            case SwapState.TIMED_OUT:
              updatedRequest.forEach((r) => {
                r.deposit = PoolActionState.Completed;
              });
              runInAction(() => {
                this.runningDeposit = false;
                this.poolDepositRequest = updatedRequest;
                this.poolDepositError = DepositError.REJECTED;
              });
              return false;
            default:
              return true;
          }
        } else {
          return false;
        }
      },
      {
        interval: 5 * 1000,
        timeout: ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION,
      },
    );
  }

  private async handleTxError(e: unknown): Promise<void> {
    console.error(e);
    captureException(e as Error);
    const error = e as { code: string; message: string };
    // if the user rejects the transaction, close the modal and return
    if (error.code && error.code === 'ACTION_REJECTED' && !this.partialDeposit) {
      runInAction(() => {
        this.poolDepositStep = PoolInteractionStep.Configure;
        this.runningDeposit = false;
      });
      return;
    }

    // change state to error
    runInAction(() => {
      this.poolDepositError = DepositError.FAILURE;
      this.runningDeposit = false;
    });
    return;
  }

  reset(): void {
    runInAction(() => {
      this.poolAction = PoolActionType.None;
      this.poolDepositRequest = undefined;
      this.poolDepositStep = undefined;
      this.partialDeposit = false;
      this.depositInfo = undefined;
      this.imbalancedDeposits = false;
      this.partialDeposit = false;
      this.runningDeposit = false;
      this.depositSlippage = SwapStore.DEFAULT_SLIPPAGE;

      this.withLiquiditySwap = false;
      this.txInputs = undefined;
      this.liquiditySwapFinalAmounts = undefined;
      this.liquiditySwapFinalValues = undefined;
    });
    clearPersistedStore(this);
  }
}
