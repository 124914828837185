import { LockClosedIcon, LockOpenIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { TokenInfo } from '@uniswap/token-lists';
import { useEffect, useState } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { TokenDisplay } from '~/modules/common';
import { Input } from '~/modules/common/components/Input';
import { TokenWeightInputLoader } from '~/modules/pools/components/TokenWeightInputLoader';

import { getAssetKey } from '../utils';

export interface TokenWeightInputProps {
  token: TokenInfo;
  onChange: (token: TokenInfo, amount?: number) => void;
  disabled?: boolean;
  error?: string;
  onClick: () => void;
  chainId: number | string;
  onRemoveToken: (token: TokenInfo) => void;
  value?: number;
  isLocked: boolean;
  onClickLockWeight?: (assetKey: string) => void;
  onClickUnlockWeight?: (assetKey: string) => void;
  isRemoveDisabled: boolean;
  isLockDisabled: boolean;
}

export const TokenWeightInput = ({
  token,
  value,
  onChange,
  onClick,
  onRemoveToken,
  isLockDisabled,
  isRemoveDisabled,
  onClickLockWeight,
  onClickUnlockWeight,
  isLocked,
}: TokenWeightInputProps) => {
  const [weight, setWeight] = useState(value);
  const [isWeightLocked, setIsWeightLocked] = useState(isLocked);

  useEffect(() => {
    setWeight(value);
  }, [value]);

  const assetKey = getAssetKey({
    address: token.address,
    chainId: token.chainId.toString(),
  });

  useEffect(() => {
    if (isWeightLocked) {
      onClickLockWeight?.(assetKey);
    } else {
      onClickUnlockWeight?.(assetKey);
    }
  }, [isWeightLocked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setWeight(undefined);
      onChange(token, undefined);
      return;
    }
    const number = Number(e.target.value);
    if (number > 100 || number < 0 || isNaN(number)) {
      return;
    }
    setWeight(number);
    onChange(token, number);
  };

  return (
    <div className="flex items-center justify-between gap-3">
      <div className="border-gray flex h-[52px] w-3/4 items-center rounded-lg border md:w-9/12">
        <div onClick={onClick} className="flex w-full cursor-pointer items-center justify-between px-4 text-grey">
          <div className="flex items-center gap-1">
            <TokenDisplay chainId={token.chainId.toString()} token={token.address} showChain showChainIcon showSymbol />
          </div>
          <div className="">
            <Caret className="-rotate-90 text-grey-700" />
          </div>
        </div>
      </div>

      <div className="border-gray relative flex h-[52px] w-1/4 items-center overflow-hidden rounded-lg border p-1 pr-3 md:w-2/12">
        <div className="w-[42px]">
          <Input
            numeric
            className="border-gray h-[62px] rounded-[12px] p-0 text-right text-gray-700"
            max={100}
            placeholder="0"
            onChange={handleChange}
            value={weight}
            onBlur={() => setIsWeightLocked(true)}
          />
        </div>
        <span className="text-gray-700"> % </span>
        {isWeightLocked ? (
          <LockClosedIcon
            className="ml-2 h-4 w-4  cursor-pointer text-primary-600 "
            onClick={() => {
              setIsWeightLocked(false);
            }}
          />
        ) : (
          <LockOpenIcon
            onClick={() => {
              if (!isLockDisabled) {
                setIsWeightLocked(true);
              }
            }}
            className={`ml-2 h-4 w-4  text-gray-500 ${isLockDisabled ? 'opacity-30' : 'cursor-pointer'}`}
          />
        )}
      </div>

      <div
        onClick={() => {
          if (!isRemoveDisabled) {
            setIsWeightLocked(false);
            onRemoveToken(token);
          }
        }}
        className={`flex h-[24px] w-[24px]  items-center justify-center rounded-full bg-gray-100  ${
          isRemoveDisabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'
        }`}
      >
        <XMarkIcon className="h-4 w-4 text-gray-500" />
      </div>
    </div>
  );
};

TokenWeightInput.Loader = TokenWeightInputLoader;

export default TokenWeightInput;
