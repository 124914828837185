import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';

import { StoreContext, useInterval } from '~/modules/common';
import { SwapStep } from '~/modules/swap';

import { ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION } from '../constants';
import { PendingTxn, PendingTxnType } from '../interfaces';
import { PendingDepositCard } from './PendingDepositCard';
import { PendingPoolCreationCard } from './PendingPoolCreationCard';
import { PendingSwapCard } from './PendingSwapCard';
import { PendingWithdrawCard } from './PendingWithdrawCard';

export const PendingTxnSection = observer(() => {
  const { lobby: lobbyStore, swap: swapStore } = useContext(StoreContext);
  const [showTransaction, setShowTransactions] = useState(false);
  const { allPendingTxns } = lobbyStore;

  useInterval(() => {
    swapStore.updateSwapInfo();
  }, 3_000);

  useInterval(() => {
    const newCurrentTime = new Date();
    runInAction(() => {
      Array.from(swapStore.pendingSwaps.values()).forEach((s) => {
        const newDuration = newCurrentTime.getTime() - s.submittedAt.getTime();
        s.totalDuration = newDuration;
      });

      Array.from(lobbyStore.pendingDeposits).forEach((d) => {
        if (!d.depositDetails) return;
        const totalDuration = newCurrentTime.getTime() - d.submittedAt.getTime();
        const timeRemaining = Math.max(ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION - totalDuration, 0);
        const progress = (1 - timeRemaining / ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION) * 100;
        d.depositDetails = {
          ...d.depositDetails,
          progress: isNaN(progress) ? 0 : progress,
        };
      });

      Array.from(lobbyStore.pendingWithdrawals).forEach((d) => {
        if (!d.withdrawalDetails) return;
        const totalDuration = newCurrentTime.getTime() - d.submittedAt.getTime();
        const timeRemaining = Math.max(ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION - totalDuration, 0);
        const progress = (1 - timeRemaining / ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION) * 100;
        d.withdrawalDetails = {
          ...d.withdrawalDetails,
          progress: isNaN(progress) ? 0 : progress,
        };
      });

      Array.from(lobbyStore.pendingCreations).forEach((d) => {
        if (!d.poolCreationDetails) return;
        const totalDuration = newCurrentTime.getTime() - d.submittedAt.getTime();
        const timeRemaining = Math.max(ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION - totalDuration, 0);
        const progress = (1 - timeRemaining / ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION) * 100;
        d.poolCreationDetails = {
          ...d.poolCreationDetails,
          progress: isNaN(progress) ? 0 : progress,
        };
      });
    });
  }, 1_000);

  const handleShowSwapDetails = (txn: PendingTxn) => {
    if (!txn.swap) return;
    const swap = txn.swap;
    runInAction(() => {
      swapStore.lastQuote = swap.quote;
      swapStore.swapInfo = swap.swap;
      swapStore.swapStep = SwapStep.InProgress;
      swapStore.swapTransactionHash = swap.hash;
      swapStore.recentPendingSwap = undefined;
    });
  };

  let leavingTimeout: string | number | NodeJS.Timeout | undefined;

  return (
    <>
      {allPendingTxns.length > 0 ? (
        <div
          className="relative hidden flex-col items-end md:flex"
          onMouseEnter={() => {
            setShowTransactions(true);
          }}
        >
          <div
            onMouseLeave={() => {
              leavingTimeout = setTimeout(() => {
                setShowTransactions(false);
              }, 200);
            }}
            className="flex h-[20px] w-[76px] items-center justify-between rounded-full bg-black pl-[6px] pr-[6px]"
          >
            <div className="h-2 w-2 rounded-full bg-white" />
            <div className="h-2 w-2 rounded-full bg-emerald" />
            <div className="absolute w-[60px] animate-slide">
              <div className="h-1 w-1 animate-color rounded-full" />
            </div>
          </div>
          <div
            className={`absolute z-10 w-[320px] translate-y-10 flex-col space-y-2 ${
              showTransaction ? ' flex' : ' hidden'
            }`}
            onMouseEnter={() => {
              if (Boolean(leavingTimeout)) {
                clearTimeout(leavingTimeout);
              }
              setShowTransactions(true);
            }}
            onMouseLeave={() => {
              setShowTransactions(false);
            }}
          >
            {allPendingTxns.length > 0 &&
              allPendingTxns.map((txn, i) => {
                if (!txn) {
                  return null;
                }
                switch (txn.type) {
                  case PendingTxnType.Deposit:
                    return <PendingDepositCard txnDetails={txn} key={`lobby-deposit-progress-${i}`} />;
                  case PendingTxnType.Swap:
                    return (
                      <PendingSwapCard
                        key={`lobby-swap-progress--${i}`}
                        handleShowDetails={handleShowSwapDetails}
                        pendingTxn={txn}
                      />
                    );
                  case PendingTxnType.Withdraw:
                    return <PendingWithdrawCard txnDetails={txn} key={`lobby-withdraw-progress-${i}`} />;
                  case PendingTxnType.Creation:
                    return <PendingPoolCreationCard txnDetails={txn} key={`lobby-pool-creation-progress-${i}`} />;
                  default:
                    return null;
                }
              })}
          </div>
        </div>
      ) : null}
    </>
  );
});

export default PendingTxnSection;
