import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '~/config';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { StoreContext } from '~/modules/common';
import { PoolCreateModal } from '~/modules/pools/components/PoolCreateModal';
import { PoolCreatePageButton } from '~/modules/pools/components/PoolCreatePageButton';
import { PoolCreateSummary } from '~/modules/pools/components/PoolCreateSummary';
import { PoolCreateWithdrawModal } from '~/modules/pools/components/PoolCreateWithdrawModal';
import { usePoolCreate } from '~/modules/pools/hooks/usePoolCreate';
import { ChainSelector } from '~/modules/swap';

import { PoolCreateForm } from '../components';
import { PoolCreateFormStep, PoolCreateStep } from '../enums';

export const PoolCreatePage = observer(() => {
  const navigate = useNavigate();

  const { wallet, pool, catalyst } = useContext(StoreContext);
  const {
    canClickCreate,
    handlers,
    selectTokenModalOpen,
    selectedTokensAndChain,
    selectedTokenWeights,
    hasSelectedMaxTokens,
    totalTokenWeight,
    totalDepositValue,
    assetWeights,
    assetAmounts,
    poolType,
    poolFee,
    poolCreateFormStep,
    feeDetails,
    isSimilarToExistingPool,
    selectedTokensBalanceValue,
    assetBalances,
    errors,
    assetWeightsLock,
  } = usePoolCreate({ poolStore: pool, walletStore: wallet, catalystStore: catalyst });

  return (
    <div>
      <PoolCreateModal
        poolCreateRequests={pool.poolCreateRequests}
        onCancel={handlers.cancelCreate}
        createPoolStep={pool.poolCreateStep}
        totalLiquidity={totalDepositValue}
        totalCost={feeDetails}
        completeCreation={handlers.completePoolCreate}
        resumeDeposit={handlers.resumePoolCreation}
        onWithdraw={handlers.withdrawFunds}
        hasSavedPoolCreation={pool.hasPendingPoolCreateRequests}
        open={pool.poolCreateStep === PoolCreateStep.Approval || pool.poolCreateStep === PoolCreateStep.Paused}
        poolFee={poolFee}
        poolType={poolType}
        canCloseModal={!pool.runningPoolCreate}
      />
      <ChainSelector
        onSelect={handlers.addToken}
        open={selectTokenModalOpen}
        onClose={handlers.closeSelectTokenModal}
        hideNativeAssets={true}
      />
      <PoolCreateWithdrawModal
        poolWithdrawRequests={pool.poolWithdrawRequests}
        createPoolStep={pool.poolCreateStep}
        onCancel={handlers.cancelCreate}
        onResume={handlers.showResumePoolCreation}
      />
      <div className="mx-auto mb-[80px] mt-[80px] flex flex-col justify-center px-3 md:w-[1224px]">
        <div className="flex w-[362px] flex-col ">
          <div onClick={() => navigate(routes.pools)} className="flex cursor-pointer flex-row items-center space-x-2">
            <div className="pl-1">
              <Caret className="text-grey-500" />
            </div>
            <div className="text-xs text-grey-500">BACK TO LIST</div>
          </div>
        </div>

        <div className="mt-[40px] flex flex-col gap-6 md:flex-row md:items-start md:gap-24">
          <div className="flex flex-col gap-2 rounded-[20px] bg-white shadow-2xl md:w-5/6">
            <span className="border-b px-5 py-6 font-subheader text-[24px]">Create Pool</span>

            <PoolCreateForm
              poolType={poolType}
              onSelectPoolType={handlers.setPoolType}
              onClickOpenSelectTokenModal={handlers.openSelectTokenModal}
              selectedTokensAndChain={selectedTokensAndChain}
              assetWeights={assetWeights}
              onChangeTokenWeight={handlers.setTokenWeight}
              onClickRemoveToken={handlers.removeToken}
              totalTokenWeight={totalTokenWeight}
              hasSelectedMaxTokens={hasSelectedMaxTokens}
              assetAmounts={assetAmounts}
              onChangeTokenDepositAmount={handlers.setTokenAmount}
              poolFee={poolFee}
              onChangePoolFee={handlers.setPoolFee}
              totalLiquidity={totalDepositValue}
              poolCreateStep={poolCreateFormStep}
              setPoolCreateStep={handlers.setPoolCreateFormStep}
              isSimilarToExistingPool={isSimilarToExistingPool}
              selectedTokenTotalBalance={selectedTokensBalanceValue}
              selectedTokenBalances={assetBalances}
              onClickLockWeight={handlers.onLockAssetWeight}
              onClickUnlockWeight={handlers.onUnlockAssetWeight}
              assetweightLocked={assetWeightsLock}
            />
          </div>

          <div className="flex flex-col gap-6 ">
            <PoolCreateSummary
              selectedTokens={selectedTokensAndChain}
              selectedWeights={selectedTokenWeights}
              totalLiq={totalDepositValue}
              feeDetails={feeDetails}
            />

            <PoolCreatePageButton enabled={canClickCreate} onClick={handlers.startPoolCreation} />
            {poolCreateFormStep === PoolCreateFormStep.ReadyToCreate ? (
              <div className="flex flex-col items-center gap-3">
                {canClickCreate && !isSimilarToExistingPool ? (
                  <div className="flex items-center gap-2 text-xs text-primary-500">
                    <CheckCircleIcon className="h-4 w-4" /> Unique Pool
                  </div>
                ) : null}
                {errors.map((err) => {
                  return (
                    <div className="flex items-center gap-2 text-xs text-red-700">
                      <ExclamationTriangleIcon className="h-4 w-4" /> {err.message}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PoolCreatePage;
