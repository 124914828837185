import { useNavigate } from 'react-router-dom';

import { routes } from '~/config';
import { Lobby } from '~/modules/lobby';
import { NavItem } from '~/modules/navigation';
import ConnectButton from '~/modules/navigation/components/ConnectButton';
import { InfoBox, InfoBoxOptionProps } from '~/modules/navigation/components/InfoBox';
import { MobileNav } from '~/modules/navigation/components/MobileNav';

import { NAV_ITEMS } from '../constants/nav.constants';

export function NavBar() {
  const navigate = useNavigate();

  const infoBoxOptions: InfoBoxOptionProps[] = [
    { title: 'GitHub', href: 'https://github.com/catalystdao' },
    { title: 'Terms of Service', onClick: async () => navigate(routes.termsOfService) },
    { title: 'Privacy Policy', onClick: async () => navigate(routes.privacyPolicy) },
  ];

  return (
    <div className="z-10 flex h-16 flex-row items-center justify-between px-3 py-6 md:h-20 md:px-10">
      <div className="hidden items-center md:flex">
        <a className="cursor-pointer" onClick={() => navigate(routes.swap)} href="#">
          <img className="mr-16" src={'/img/logo_dark_web.svg'} alt="Catalyst Logo" height={150} width={150} />
        </a>
        <div className="flex gap-5">
          {NAV_ITEMS.map(({ title, href, matches }) => (
            <NavItem key={title} value={title} href={href} matches={matches} />
          ))}
          <InfoBox options={infoBoxOptions} />
        </div>
      </div>

      <div className="flex items-center gap-3 md:hidden">
        <img src={'/img/logo_lynx_sm.svg'} alt="Catalyst" className="h-[40px]" />
        <MobileNav />
      </div>
      <div className="flex items-center gap-6">
        <Lobby />
        <ConnectButton />
      </div>
    </div>
  );
}

export default NavBar;
