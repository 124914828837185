import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { getPoolRoute } from '~/config/utils/routes.utils';
import { ReactComponent as Close } from '~/img/close.svg';
import { formatCurrency, TokenDisplay } from '~/modules/common';
import { getAssetKey, PoolCreateStep } from '~/modules/pools';
import { PoolCreateModal } from '~/modules/pools/components/PoolCreateModal';

import { PendingTxn, PendingTxnStatus } from '../interfaces';

interface CompletedPoolCreationCardProps {
  txnDetails: PendingTxn;
  handleCompletedPoolCreation: (hash: string) => void;
}

export const CompletedPoolCreationCard: React.FC<CompletedPoolCreationCardProps> = ({
  txnDetails,
  handleCompletedPoolCreation,
}: CompletedPoolCreationCardProps) => {
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { poolCreationDetails, status } = txnDetails;
  if (!poolCreationDetails) {
    return null;
  }
  const { feeDetails, requests, totalLiquidity, poolAssets, poolFee, poolType } = poolCreationDetails;

  if (!requests || !requests.length) {
    return null;
  }

  const createdPoolId = requests.find((req) => req.deployedPoolId)?.deployedPoolId;

  if (!createdPoolId) {
    return null;
  }

  const handleCloseModal = () => {
    setIsDetailsOpen(false);
    handleCompletedPoolCreation(txnDetails.hash);
  };

  const handleComplete = () => {
    navigate(getPoolRoute(createdPoolId));
    handleCompletedPoolCreation(txnDetails.hash);
  };

  const handleViewDetails = () => {
    setIsDetailsOpen(true);
  };

  const isError = status === PendingTxnStatus.Failed;

  return (
    <>
      <div className="flex min-w-[274px] flex-col rounded-[25px] bg-white shadow">
        <div className="flex w-full justify-end px-5 pb-2 pt-5">
          <div className="z- cursor-pointer" onClick={handleComplete}>
            <Close className="text-grey-400" />
          </div>
        </div>
        <div className="flex flex-col items-center px-6 pb-8">
          {!isError && (
            <>
              <div className="text-[20px] leading-[17px]">Pool Creation Completed</div>
              <div className="flex w-full justify-between pb-6 pt-8">
                <div className="pb-2">
                  {
                    <div className="gap flex text-xs">
                      {poolAssets.map((token) => (
                        <TokenDisplay
                          chainId={token.chainId.toString()}
                          key={getAssetKey({
                            chainId: token.chainId.toString(),
                            address: token.address,
                          })}
                          token={token.address}
                          size="sm"
                          showSymbol={false}
                        />
                      ))}
                    </div>
                  }
                </div>
                <div className="text-xs text-gray-800">{formatCurrency(totalLiquidity)} </div>
              </div>
            </>
          )}

          {isError && (
            <>
              <div className="mb-6 flex flex-col items-center gap-4 p-4">
                <div className="text-[20px] leading-[17px] text-red-600">Pool Creation Failed</div>
                <div className="text-sm">Please withdraw your funds from the invalid vaults</div>
              </div>
            </>
          )}

          <div
            onClick={handleComplete}
            className="mb-2 flex w-full cursor-pointer justify-center rounded-[18px] bg-primary py-[5px] text-[12px] leading-[10px] text-white"
          >
            View Pool
          </div>
          <div
            onClick={handleViewDetails}
            className="flex w-full cursor-pointer justify-center rounded-[18px] bg-primary py-[5px] text-[12px] leading-[10px] text-white"
          >
            View Receipt
          </div>
        </div>
      </div>
      {createPortal(
        <PoolCreateModal
          totalLiquidity={totalLiquidity}
          totalCost={feeDetails}
          poolCreateRequests={requests}
          open={isDetailsOpen}
          hasSavedPoolCreation={false}
          onCancel={handleCloseModal}
          createPoolStep={PoolCreateStep.Completed}
          completeCreation={handleComplete}
          poolFee={poolFee}
          poolType={poolType}
        />,
        document.body,
      )}
    </>
  );
};
