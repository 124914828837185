import { ChevronDownIcon, LockOpenIcon, XMarkIcon } from '@heroicons/react/20/solid';

export interface TokenWeightInputLoaderProps {
  onClick?: () => void;
  nextTokenNumber?: number;
  isAddingToken?: boolean;
}

export const TokenWeightInputLoader = ({ onClick, nextTokenNumber, isAddingToken }: TokenWeightInputLoaderProps) => {
  return (
    <div
      onClick={onClick}
      className={`flex h-[56px] w-full items-center justify-between gap-3 rounded-[12px] ${
        onClick && 'cursor-pointer'
      }`}
    >
      <div className="border-gray  flex h-full w-9/12 items-center rounded-lg border-[1.5px] p-3">
        <div className="flex w-full items-center justify-between text-lg text-grey">
          <div className="flex items-center ">
            <div className="flex items-end">
              <div className="h-8 w-8 rounded-full bg-grey-200"></div>
              <div className="h-4 w-4 -translate-x-2 rounded-full border-[1.6px] border-white bg-grey-200"></div>
            </div>
            <span className="text-base text-grey-500">
              {isAddingToken ? `Add token ${nextTokenNumber || ''}` : `Select token ${nextTokenNumber || ''}`}
            </span>
          </div>
          <ChevronDownIcon className="h-5 w-5" />
        </div>
      </div>
      <div className="flex h-full w-2/12 items-center justify-center gap-2 rounded-lg border border-gray-200 px-[6px] py-4 opacity-30">
        <span className="text-base text-gray-700">50%</span>
        <LockOpenIcon className="h-4 w-4  text-gray-500" />
      </div>
      <div className=" flex h-[24px] w-[24px] items-center justify-center rounded-full bg-gray-100 opacity-30 ">
        <XMarkIcon className="h-4 w-4 text-gray-500" />
      </div>
    </div>
  );
};

export default TokenWeightInputLoader;
