import { PoolAsset } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { getToken } from '@catalabs/catalyst-token-lists';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { BoltIcon, ShieldCheckIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

import { CatalystNetwork, routes } from '~/config';
import {
  CircleLoader,
  formatCurrency,
  formatPercentage,
  formatValue,
  Modal,
  PriceImpact,
  SwapReviewLine,
  TokenDisplay,
} from '~/modules/common';
import PoolApproval from '~/modules/pools/components/PoolApproval';
import PoolChainSwap from '~/modules/pools/components/PoolChainSwap';
import { PoolInteractionDisplay } from '~/modules/pools/components/PoolInteractionDisplay';
import PoolInteractionProgressBar from '~/modules/pools/components/PoolInteractionProgressBar';
import { DepositError, PoolActionState, PoolInteractionStep } from '~/modules/pools/enums';
import { getAssetKey, getDepositText } from '~/modules/pools/utils';
import { SwapCosts } from '~/modules/swap';

import { LIQUIDITY_SWAP_DOCS_URL } from '../constants';
import { ChainDeposit, ChainDepositInfo } from '../interfaces';
import { InlineLink } from './ImbalancedDeposit';

interface PoolDepsoitModalProps {
  handleTryAgain?: () => void;
  handleCompleteDeposit?: () => void;
  poolDepositStep: PoolInteractionStep;
  poolDepositRequest: ChainDeposit[];
  poolDepositError?: DepositError;
  depositInfo: ChainDepositInfo;
  partialDeposit: boolean;
  liquiditySwapFinalAmounts?: Map<string, number>;
  liquiditySwapFinalValues?: Map<string, number>;
  withLiquiditySwap: boolean;
  open: boolean;
  handleCloseModal: () => void;
  poolAssets: PoolAsset[];
  canCloseModal?: boolean;
}

export const PoolDepsoitModal = ({
  handleTryAgain,
  handleCompleteDeposit,
  open,
  handleCloseModal,
  poolDepositRequest,
  poolDepositError,
  depositInfo,
  liquiditySwapFinalAmounts,
  liquiditySwapFinalValues,
  withLiquiditySwap,
  poolAssets,
  canCloseModal = true,
}: PoolDepsoitModalProps) => {
  const navigate = useNavigate();
  const allDepositItems = poolDepositRequest.flatMap((r) => r.request);
  const { poolShare, totalLiquidity, priceImpact, totalCost } = depositInfo;

  const hasStartedDeposits = poolDepositRequest.some(
    (r) => !(r.deposit === PoolActionState.Inactive || r.deposit === PoolActionState.Pending),
  );
  const hasConfirmedAllDeposits = poolDepositRequest.every((r) => r.deposit === PoolActionState.Confirmed);
  const hasCompletedDeposit = poolDepositRequest.every((r) => r.deposit === PoolActionState.Completed);

  return (
    <Modal open={open} onClose={handleCloseModal} hasError={Boolean(poolDepositError)} canClose={canCloseModal}>
      <div className="flex flex-col items-center gap-4 md:w-[552px]">
        {!poolDepositError && withLiquiditySwap && hasConfirmedAllDeposits && (
          <div className="mb-4 w-full rounded-[25px] bg-white p-4 md:w-[552px]">
            <div className="flex flex-col gap-2">
              <span className=" text-[14px] text-primary">Deposit with Liquidity swap</span>
              <div className="flex items-start">
                <span className="text-[12px] leading-5 text-gray-800">
                  Your deposit includes liquidity swaps, this can take a while.
                  <br />
                  Liquidity swaps take ~30 minutes to complete. <br />
                  In that period, you'll have missing amounts from your deposit displayed on the Ul.
                  <br />
                  Rest assured that they'll be reflected correctly after ~30 minutes have elapsed.{' '}
                  <InlineLink href={LIQUIDITY_SWAP_DOCS_URL} text="Learn more." size="xs" />
                </span>
                <div className="relative flex items-center justify-center text-primary">
                  <CircleLoader loading className="text-gray-200" />
                  <BoltIcon className="absolute h-4 w-4" />
                </div>
              </div>
            </div>
          </div>
        )}
        {!poolDepositError && hasStartedDeposits && (
          <div className="flex w-full flex-col items-center rounded-[25px] bg-white p-5 md:w-[552px]">
            <span className="mb-4 w-full font-semibold text-xs uppercase leading-4 tracking-wide text-gray-800">
              {hasCompletedDeposit ? 'Deposit Completed' : 'Deposit in progress'}
            </span>
            <div className="flex w-full flex-col space-y-2">
              {poolDepositRequest.map((r) => {
                if (r.deposit === PoolActionState.Inactive || r.deposit === PoolActionState.Pending) {
                  return null;
                }
                const targetNetwork = CatalystNetwork.getCatalystNetwork(r.chainId);
                const allTokens = r.request.map((req) => {
                  const token = getToken(req.chainId, req.useGasToken ? GAS_TOKEN_IDENTIFIER : req.address);
                  return token.symbol;
                });
                const explorerUrl = r.hash ? targetNetwork.getTransactionUrl(r.hash) : undefined;
                return (
                  <PoolInteractionProgressBar
                    key={`deposit-progress-bar-${r.chainId}-${r.hash}`}
                    interactionText={`Deposit ${getDepositText(allTokens)} on ${targetNetwork.config.name}`}
                    state={r.deposit}
                    explorerUrl={explorerUrl}
                  />
                );
              })}
            </div>
          </div>
        )}
        {!poolDepositError && hasCompletedDeposit && !withLiquiditySwap && (
          <>
            <div className="mt-5 flex w-full flex-col gap-4 md:w-[552px]">
              <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
                {allDepositItems.map((d) => {
                  const amount =
                    d.withLiquiditySwap && d.finalLiquiditySwapAmount
                      ? d.finalLiquiditySwapAmount.slice(0, 9)
                      : d.displayAmount.slice(0, 9);

                  const value = d.withLiquiditySwap && d.finalLiquiditySwapValue ? d.finalLiquiditySwapValue : d.value;

                  return (
                    <div key={`deposit-completed-display-${d.chainId}-${d.address}`} className="flex space-x-4">
                      <div className="flex w-3/4 justify-between rounded-[15px] border border-gray-200 p-5">
                        <div className="flex items-center gap-2">
                          <TokenDisplay showSymbol={false} chainId={d.chainId} token={d.address} />
                          <span>{getToken(d.chainId, d.useGasToken ? GAS_TOKEN_IDENTIFIER : d.address).symbol}</span>
                        </div>
                        <div className="ml-4 mt-1 flex flex-col items-end">
                          <span className="font-semibold leading-[28px] text-gray-800 md:text-[32px]">{amount}</span>
                          <span className="text-[14px] font-normal text-gray-600">{formatCurrency(d.value)}</span>
                        </div>
                      </div>
                      <div className="flex w-1/4 items-center justify-center rounded-[10px] bg-gray-100">
                        <span className="font-medium leading-6 text-gray-600">
                          {formatPercentage((value / totalLiquidity) * 100)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <SwapReviewLine title="Total Liquidity" value={formatCurrency(totalLiquidity)} />
              <SwapReviewLine title="Pool Share" value={poolShare === 0 ? '-' : `${formatPercentage(poolShare)}`} />
              <PriceImpact value={depositInfo?.priceImpact || 0} />
              <SwapCosts feeDetails={depositInfo.totalCost || []} />
            </div>
            <div
              onClick={handleCompleteDeposit}
              className="itemx-center mt-16 flex w-full cursor-pointer justify-center rounded-[145px] bg-primary px-12 py-8 text-white md:w-[552px]"
            >
              View Position
            </div>
          </>
        )}
        {!poolDepositError && hasConfirmedAllDeposits && withLiquiditySwap && (
          <>
            <div className="mt-5 flex w-full flex-col gap-4 md:w-[552px]">
              <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
                {poolAssets.map((asset) => {
                  const assetKey = getAssetKey(asset);
                  const request = allDepositItems.find((d) => getAssetKey(d) === assetKey);
                  const amount = liquiditySwapFinalAmounts?.get(assetKey);

                  const value = liquiditySwapFinalValues?.get(assetKey);

                  if (!amount || !value) {
                    return null;
                  }
                  const weight = (value / totalLiquidity) * 100;
                  return (
                    <div key={`deposit-completed-display-${assetKey}`} className="flex space-x-4">
                      <div className="flex w-3/4 justify-between rounded-[15px] border border-gray-200 p-5">
                        <div className="flex items-center gap-2">
                          <TokenDisplay showSymbol={false} chainId={asset.chainId} token={asset.address} />
                          <span>
                            {
                              getToken(asset.chainId, request?.useGasToken ? GAS_TOKEN_IDENTIFIER : asset.address)
                                .symbol
                            }
                          </span>
                        </div>
                        <div className="ml-4 mt-1 flex flex-col items-end">
                          <span className="font-semibold leading-[28px] text-gray-800 md:text-[32px]">
                            {formatValue(amount, 6)}
                          </span>
                          <span className="text-[14px] font-normal text-gray-600">{formatCurrency(value)}</span>
                        </div>
                      </div>
                      <div className="flex w-1/4 items-center justify-center rounded-[10px] bg-gray-100">
                        <span className="font-medium leading-6 text-gray-600">
                          {isNaN(weight) ? '- %' : formatPercentage(weight)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {!poolDepositError && hasCompletedDeposit && withLiquiditySwap && (
          <>
            <div className="mt-5 flex w-full flex-col gap-4 md:w-[552px]">
              <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
                {poolAssets.map((asset) => {
                  const assetKey = getAssetKey(asset);
                  const request = allDepositItems.find((d) => getAssetKey(d) === assetKey);
                  const amount = liquiditySwapFinalAmounts?.get(assetKey);

                  const value = liquiditySwapFinalValues?.get(assetKey);
                  if (!amount || !value) {
                    return null;
                  }
                  return (
                    <div key={`deposit-completed-display-${assetKey}`} className="flex space-x-4">
                      <div className="flex w-3/4 justify-between rounded-[15px] border border-gray-200 p-5">
                        <div className="flex items-center gap-2">
                          <TokenDisplay showSymbol={false} chainId={asset.chainId} token={asset.address} />
                          <span>
                            {
                              getToken(asset.chainId, request?.useGasToken ? GAS_TOKEN_IDENTIFIER : asset.address)
                                .symbol
                            }
                          </span>
                        </div>
                        <div className="ml-4 mt-1 flex flex-col items-end">
                          <span className="font-semibold leading-[28px] text-gray-800 md:text-[32px]">
                            {formatValue(amount, 6)}
                          </span>
                          <span className="text-[14px] font-normal text-gray-600">{formatCurrency(value)}</span>
                        </div>
                      </div>
                      <div className="flex w-1/4 items-center justify-center rounded-[10px] bg-gray-100">
                        <span className="font-medium leading-6 text-gray-600">
                          {formatPercentage((value / totalLiquidity) * 100)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <SwapReviewLine title="Total Liquidity" value={formatCurrency(totalLiquidity)} />
              <SwapReviewLine title="Pool Share" value={poolShare === 0 ? '-' : `${formatPercentage(poolShare)}`} />
              <SwapReviewLine title="Price Impact" value={`${formatPercentage(priceImpact)}`} />
              <SwapCosts feeDetails={totalCost} />
            </div>
            <div
              onClick={handleCompleteDeposit}
              className="itemx-center mt-16 flex w-full cursor-pointer justify-center rounded-[145px] bg-primary px-12 py-8 text-white md:w-[552px]"
            >
              View Position
            </div>
          </>
        )}
        {!poolDepositError && (
          <div className="flex flex-col gap-4">
            {poolDepositRequest.map((r, i) => {
              if (r.deposit === PoolActionState.Completed || r.deposit === PoolActionState.Confirmed) {
                return null;
              }

              const targetNetwork = CatalystNetwork.getCatalystNetwork(r.chainId);
              const allTokens = r.request.map((req) => {
                const token = getToken(req.chainId, req.useGasToken ? GAS_TOKEN_IDENTIFIER : req.address);
                return token.symbol;
              });
              const chainSwapText = `Switch to ${targetNetwork.config.name}`;

              return (
                <div key={`pool-deposit-${r.chainId}`}>
                  {/* Chain Swap Display */}
                  {r.chainSwap !== PoolActionState.Completed && (
                    <PoolChainSwap chainSwapText={chainSwapText} actionState={r.chainSwap} />
                  )}

                  {/* Token Approvals Display */}
                  <div className={`mb-4 ${i > 0 ? 'mt-4' : ''}`}>
                    {r.request.map((req) =>
                      req.useGasToken ? null : (
                        <PoolApproval
                          key={`pool-deposit-approval-${req.chainId}-${req.address}`}
                          approval={req.approval}
                          token={getToken(req.chainId, req.address)}
                          chainId={req.chainId}
                        />
                      ),
                    )}
                  </div>

                  {/* Token Deposit Display */}
                  <PoolInteractionDisplay
                    index={i}
                    allDepositItems={allDepositItems}
                    req={r}
                    depositText={getDepositText(allTokens)}
                    poolDepositRequest={poolDepositRequest}
                  />
                </div>
              );
            })}
          </div>
        )}
        {!poolDepositError && withLiquiditySwap && hasConfirmedAllDeposits && canCloseModal && (
          <div className="mt-6 w-full rounded-[25px] bg-white p-4 md:w-[552px]">
            <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
              <div className="text-[14px] text-grey-500">Continue to navigate while we finish here...</div>
              <div className="flex h-6 gap-2 text-[12px] text-white">
                <div
                  onClick={() => {
                    if (canCloseModal) {
                      handleCompleteDeposit?.();
                    }
                  }}
                  className="flex cursor-pointer items-center rounded-xl bg-primary px-3"
                >
                  View Position
                </div>
                <div
                  onClick={() => {
                    if (canCloseModal) {
                      handleCompleteDeposit?.();
                      navigate(routes.swap);
                    }
                  }}
                  className="flex cursor-pointer items-center rounded-xl bg-primary px-3"
                >
                  Swap Assets
                </div>
              </div>
            </div>
          </div>
        )}
        {poolDepositError && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-center gap-2 rounded-[25px] bg-white p-5 md:w-[552px]">
              <span className="w-full font-semibold text-xs uppercase leading-4 tracking-wide text-red-600">
                Deposit Failed
              </span>
              <div className="w-full">
                <p className="w-full text-left text-xs font-medium">
                  Your deposit transaction has failed. Please try again later.
                </p>
              </div>
              <div className="flex w-full items-center gap-2 rounded-2xl bg-gray-200 px-3 py-2">
                <ShieldCheckIcon className="h-6 w-6 text-gray-400" />
                <p className="text-left text-xs font-medium text-gray-600">Funds are safe in your wallet</p>
              </div>
              {poolDepositRequest.map((r) => {
                if (r.hash) {
                  const targetNetwork = CatalystNetwork.getCatalystNetwork(r.chainId);
                  const explorerUrl = r.hash ? targetNetwork.getTransactionUrl(r.hash) : undefined;
                  return (
                    <div
                      className="flex w-full cursor-pointer items-center gap-2 rounded-2xl bg-gray-200 px-3 py-2"
                      onClick={() => explorerUrl && window.open(explorerUrl, '_blank')}
                      key={r.hash}
                    >
                      <p className="text-left text-xs font-medium text-gray-600">{r.hash}</p>
                      <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    </div>
                  );
                }
              })}
            </div>
            <button
              onClick={handleTryAgain}
              className="z-30 flex h-16 items-center justify-center rounded-[25px] bg-primary text-sm text-white md:w-[552px]"
            >
              <span className="text-md leading-[14px]">Try Again</span>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PoolDepsoitModal;
