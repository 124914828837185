import { useState } from 'react';
import { createPortal } from 'react-dom';

import { formatCurrency, TokenDisplay } from '~/modules/common';
import { getAssetKey, PoolCreateStep } from '~/modules/pools';
import { PoolCreateModal } from '~/modules/pools/components/PoolCreateModal';

import { PendingTxn } from '../interfaces';

interface PendingPoolCreationCardProps {
  info?: string;
  txnDetails: PendingTxn;
  flat?: boolean;
}

export const PendingPoolCreationCard: React.FC<PendingPoolCreationCardProps> = ({
  info,
  txnDetails,
  flat,
}: PendingPoolCreationCardProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { poolCreationDetails } = txnDetails;
  if (!poolCreationDetails) {
    return null;
  }

  const { feeDetails, progress, requests, totalLiquidity, poolAssets, poolFee, poolType } = poolCreationDetails;

  if (!requests || !requests.length) {
    return null;
  }

  const handleCloseModal = () => {
    setIsDetailsOpen(false);
  };

  return (
    <>
      <div className={`flex w-full justify-center rounded-[20px] bg-white px-6 py-4 ${flat ? '' : 'shadow-2xl'}`}>
        <div className="flex w-full flex-col gap-3">
          <div className="flex justify-between ">
            <p className="text-xs text-gray-500"> Pool creation in progress</p>
            <div
              onClick={() => {
                setIsDetailsOpen(true);
              }}
              className="flex h-[20px] w-[47px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-xs text-white"
            >
              Open
            </div>
          </div>

          <div className="flex flex-col">
            <div className="relative mb-2 h-[2px] w-full rounded-full bg-grey-200">
              <progress className="absolute h-[2px] w-full rounded-full" value={progress} max={100} />
            </div>
            <div className="flex justify-between text-[14px] text-grey">
              <div className="gap flex text-xs">
                {poolAssets.map((token) => (
                  <TokenDisplay
                    chainId={token.chainId.toString()}
                    key={getAssetKey({
                      chainId: token.chainId.toString(),
                      address: token.address,
                    })}
                    token={token.address}
                    size="sm"
                    showSymbol={false}
                  />
                ))}
              </div>
              <div className="text-xs text-gray-800">{formatCurrency(totalLiquidity)} </div>
            </div>
          </div>
          {info && <p className="rounded-xl bg-gray-100 px-4 py-2 text-xs text-gray-500 ">{info}</p>}
        </div>
      </div>
      {createPortal(
        <PoolCreateModal
          totalLiquidity={totalLiquidity}
          totalCost={feeDetails}
          poolCreateRequests={requests}
          open={isDetailsOpen}
          hasSavedPoolCreation={false}
          onCancel={handleCloseModal}
          createPoolStep={PoolCreateStep.Completed}
          poolFee={poolFee}
          poolType={poolType}
        />,
        document.body,
      )}
    </>
  );
};
