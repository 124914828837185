import { ReactComponent as Completed } from '~/img/circle_check.svg';
import { displayDurationMs } from '~/modules/common/utils';
import { PoolActionState } from '~/modules/pools/enums';

export interface PoolInteractionProgressBarProps {
  interactionText: string;
  state: PoolActionState;
  duration?: number;
  explorerUrl?: string;
  durationText?: string;
}

export function PoolInteractionProgressBar({
  interactionText,
  state,
  duration,
  explorerUrl,
  durationText,
}: PoolInteractionProgressBarProps): JSX.Element {
  const inProgress = state === PoolActionState.Active || state === PoolActionState.Pending;
  const completed = state === PoolActionState.Completed || state === PoolActionState.Confirmed;
  const inactive = state === PoolActionState.Inactive;
  return (
    <div
      onClick={() => explorerUrl && window.open(explorerUrl, '_blank')}
      className={`flex w-full items-center justify-between rounded-[100px] p-2 ${
        inProgress ? 'approval animate-approval' : 'bg-grey-100'
      } ${explorerUrl ? 'cursor-pointer' : 'cursor-default'}`}
      data-testid="pool-interaction-progress"
    >
      <div className="flex items-center">
        {inProgress && (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="white" />
            <circle cx="8" cy="8" r="4" fill="#4553D3" />
          </svg>
        )}
        {inactive && (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="white" />
            <circle cx="8" cy="8" r="4" fill="grey" />
          </svg>
        )}
        {completed && <Completed />}
        <div className={`pl-[14px] ${inProgress ? 'text-white' : 'text-grey-500'}`}>
          <span>{interactionText}</span>
        </div>
      </div>
      <div className="flex text-grey-600">
        {inProgress && duration ? <div className="mr-4 text-white">{displayDurationMs(duration)}</div> : null}
        {durationText && <div className="mr-4 text-white">{durationText}</div>}
        {explorerUrl && (
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.25 3.5C2.05109 3.5 1.86032 3.57902 1.71967 3.71967C1.57902 3.86032 1.5 4.05109 1.5 4.25V12.75C1.5 13.164 1.836 13.5 2.25 13.5H10.75C10.9489 13.5 11.1397 13.421 11.2803 13.2803C11.421 13.1397 11.5 12.9489 11.5 12.75V8.75C11.5 8.55109 11.579 8.36032 11.7197 8.21967C11.8603 8.07902 12.0511 8 12.25 8C12.4489 8 12.6397 8.07902 12.7803 8.21967C12.921 8.36032 13 8.55109 13 8.75V12.75C13 13.3467 12.7629 13.919 12.341 14.341C11.919 14.7629 11.3467 15 10.75 15H2.25C1.65326 15 1.08097 14.7629 0.65901 14.341C0.237053 13.919 0 13.3467 0 12.75V4.25C0 3.65326 0.237053 3.08097 0.65901 2.65901C1.08097 2.23705 1.65326 2 2.25 2H7.25C7.44891 2 7.63968 2.07902 7.78033 2.21967C7.92098 2.36032 8 2.55109 8 2.75C8 2.94891 7.92098 3.13968 7.78033 3.28033C7.63968 3.42098 7.44891 3.5 7.25 3.5H2.25Z"
              fill={inProgress ? 'white' : '#A5A9B1'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.19404 10.753C4.26013 10.8261 4.33998 10.8855 4.42904 10.9278C4.5181 10.97 4.61461 10.9943 4.71305 10.9992C4.8115 11.0041 4.90995 10.9896 5.00278 10.9565C5.09561 10.9233 5.18099 10.8722 5.25404 10.806L14.5 2.44V5.25C14.5 5.44891 14.5791 5.63968 14.7197 5.78033C14.8604 5.92098 15.0511 6 15.25 6C15.449 6 15.6397 5.92098 15.7804 5.78033C15.921 5.63968 16 5.44891 16 5.25V0.75C16 0.551088 15.921 0.360322 15.7804 0.21967C15.6397 0.0790175 15.449 0 15.25 0H10.75C10.5511 4.19176e-09 10.3604 0.0790175 10.2197 0.21967C10.0791 0.360322 10 0.551088 10 0.75C10 0.948912 10.0791 1.13968 10.2197 1.28033C10.3604 1.42098 10.5511 1.5 10.75 1.5H13.303L4.24704 9.694C4.17391 9.76009 4.11452 9.83994 4.07227 9.929C4.03002 10.0181 4.00574 10.1146 4.00081 10.213C3.99589 10.3115 4.01042 10.4099 4.04358 10.5027C4.07673 10.5956 4.12786 10.6799 4.19404 10.753Z"
              fill={inProgress ? 'white' : '#A5A9B1'}
            />
          </svg>
        )}
      </div>
    </div>
  );
}

export default PoolInteractionProgressBar;
