import { FeeDetail, VaultType as VaultTypeAPI } from '@catalabs/catalyst-api-client';
import { getChainConfig } from '@catalabs/catalyst-chain-lists';
import { ArbitraryMessagingBridge, getChannelIdentifierOrThrow } from '@catalabs/catalyst-channel-lists';
import { CCIVersion, DeployVaultOptions, GAS_TOKEN_IDENTIFIER, VaultType } from '@catalabs/catalyst-sdk';
import { parseUnits } from '@ethersproject/units';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CatalystNetwork, formatBalance, routes, TokenBalance, toUnits } from '~/config';
import { getPoolRoute } from '~/config/utils/routes.utils';
import { CatalystStore, getMaxGasFee, WalletStore } from '~/modules/common';
import { db } from '~/modules/common/db';
import { CreatePoolType, PoolActionState, PoolCreateFormStep, PoolCreateStep } from '~/modules/pools/enums';
import {
  ApprovalRequest,
  DeployVaultRequest,
  InteroperabilityProtocol,
  PoolCreateConfig,
  PoolCreateRequest,
  SetVaultChannelsRequest,
} from '~/modules/pools/interfaces';

import { PoolStore } from '../store';
import { getAssetKey } from '../utils';
import { getPoolCreateFeeDetails } from '../utils/create-fee-detail.utils';

export interface usePoolCreateProps {
  poolStore: PoolStore;
  walletStore: WalletStore;
  catalystStore: CatalystStore;
}

const MAX_NUM_OF_TOKENS_IN_A_POOL = 8;

export function usePoolCreate({ poolStore, catalystStore, walletStore }: usePoolCreateProps) {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectTokenModalOpen, setSelectTokenModalOpen] = useState(false);
  const [assetWeights, setAssetWeights] = useState<Map<string, number>>(new Map());
  const [assetWeightsLock, setAssetWeightLock] = useState<Map<string, boolean>>(new Map());
  const [assetAmounts, setAssetAmounts] = useState<Map<string, number>>(new Map());
  const [assets, setAssets] = useState<Map<string, TokenInfo>>(new Map());
  const [poolType, setPoolType] = useState<CreatePoolType>(CreatePoolType.CLASSIC);
  const [interopProtocol, setInteropProtocol] = useState<InteroperabilityProtocol>();
  const [chainCCIs, setChainCCIs] = useState<Map<string, string>>(new Map());
  const [chainVaultFactoryAddresses, setChainVaultFactoryAddresses] = useState<Map<string, string>>(new Map());
  const [depositValueMap, setDepositValueMap] = useState<Map<string, number>>(new Map());
  const [hasSavedPoolCreation, setHasSavedPoolCreation] = useState(false);
  const [poolFee, setPoolFee] = useState(0);
  const [poolCreateFormStep, setPoolCreateFormStep] = useState<PoolCreateFormStep>(PoolCreateFormStep.Type);
  const [tokenPrices, setTokenPrices] = useState<Map<string, number>>(new Map());
  const [assetBalances, setAssetBalances] = useState<Map<string, TokenBalance>>(new Map());
  const [gasTokenBalances, setGasTokenBalances] = useState<Map<string, TokenBalance>>(new Map());
  const [ambVersion, setAmbVersion] = useState<ArbitraryMessagingBridge | null>(null);
  const [feeDetails, setFeeDetails] = useState<FeeDetail[]>([]);
  const [isSimilarToExistingPool, setIsSimilarToExistingPool] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[]>([]);

  // computed properties
  const hasSelectedMaxTokens = Array.from(assets.entries()).length >= MAX_NUM_OF_TOKENS_IN_A_POOL;
  const selectedTokensAndChain = Array.from(assets.entries());
  const selectedChains = [...new Set(Array.from(assets.values()).map((asset) => asset.chainId.toString()))];
  const selectedTokens = Array.from(assets.values());

  const selectedTokenWeights = Array.from(assetWeights.entries());
  const selectedTokenAmounts = Array.from(assetAmounts.entries());

  const allSelecteTokenWeightsAreValid = Array.from(assetWeights.values()).every((weight) => weight > 0);
  const totalTokenWeight = Array.from(assetWeights.values()).reduce((total, weight) => total + weight, 0);
  const theTotalTokenWeightsAreLessThan100 = totalTokenWeight <= 100;
  const canClickCreate =
    selectedTokens.length > 0 &&
    selectedTokensAndChain.length === selectedTokens.length &&
    selectedTokenWeights.length === selectedTokens.length &&
    selectedTokenAmounts.length === selectedTokens.length &&
    isInitialized &&
    poolType !== undefined &&
    poolStore.poolCreateStep === PoolCreateStep.Configure &&
    errors.length === 0 &&
    allSelecteTokenWeightsAreValid &&
    theTotalTokenWeightsAreLessThan100 &&
    poolCreateFormStep === PoolCreateFormStep.ReadyToCreate;
  const totalDepositValue = Array.from(depositValueMap.values()).reduce((total, value) => total + value, 0);
  const selectedTokensBalanceValue = Array.from(assetBalances.entries()).reduce((total, [assetKey, balance]) => {
    const price = tokenPrices.get(assetKey);
    if (!price) return total + 0;
    const balanceValue = price * formatBalance(balance.amount, balance.decimals);
    return total + balanceValue;
  }, 0);

  const cancelCreate = () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Configure;
    });
  };

  const resetPoolCreate = async () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Configure;
      poolStore.depositStore.poolDepositRequest = [];
    });
    setAssets(new Map());
    setAssetAmounts(new Map());
    setAssetWeights(new Map());
    setIsInitialized(false);
    setPoolType(CreatePoolType.CLASSIC);
    setInteropProtocol(undefined);
    setChainCCIs(new Map());
    setChainVaultFactoryAddresses(new Map());

    const { address } = walletStore;

    if (!address) {
      alert('Please connect your wallet');
      return;
    }
    await poolStore.clearSavedPoolCreateRequests(address);
    await clearSavedPoolState();
  };

  const completePoolCreate = async () => {
    const poolId = poolStore.poolCreateRequests.find((requests) => requests.deployedPoolId)?.deployedPoolId;
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Completed;
      poolStore.poolCreateRequests = [];
    });
    await resetPoolCreate();
    if (poolId) {
      navigate(getPoolRoute(poolId));
    } else {
      navigate(routes.pools);
    }
  };

  const prepareApproveRequests = async () => {
    const assetsArray = Array.from(assets.values());
    // prepare approve requests
    const approvalRequestsByChain = new Map<string, ApprovalRequest[]>();
    assetsArray.forEach((asset) => {
      const chainId = asset.chainId.toString();
      const assetKey = getAssetKey({ address: asset.address, chainId });
      if (chainId) {
        const request: ApprovalRequest = {
          chainId,
          asset: asset,
          address: asset.address,
          amountValue: depositValueMap.get(assetKey) || 0,
          approval: PoolActionState.Inactive,
          amount: toUnits(assetAmounts.get(assetKey)?.toString() || '0', asset.decimals),
          vaultFactoryAddress: chainVaultFactoryAddresses.get(chainId) || '',
        };
        const requests = approvalRequestsByChain.get(chainId);
        if (requests) {
          requests.push(request);
        } else {
          approvalRequestsByChain.set(chainId, [request]);
        }
      }
    });

    return approvalRequestsByChain;
  };

  const prepareVaultDeployRequests = async () => {
    // prepare pool create requests
    const deployVaultRequests = new Map<string, DeployVaultRequest>();
    const chainAssets = new Map<string, TokenInfo[]>();

    // create a map of assets by chain
    selectedTokens.map((asset) => {
      const chainId = asset.chainId.toString();
      if (chainId) {
        const assets = chainAssets.get(chainId);
        if (assets) {
          assets.push(asset);
        } else {
          chainAssets.set(chainId, [asset]);
        }
      }
    });

    for (const chainAsset of chainAssets) {
      const [chainId, assets] = chainAsset;
      const weights = computeAssetWeights(assets);
      const amounts: bigint[] = assets.map((asset) => {
        const assetKey = getAssetKey({ address: asset.address, chainId: asset.chainId.toString() });
        return BigInt(parseUnits(assetAmounts.get(assetKey)?.toString() || '0', asset.decimals).toString());
      });
      // TODO: this needs to be configurable on the UI for amplified pools
      const amplification = BigInt(
        (poolType === CreatePoolType.STABLESWAP ? parseUnits('0.5', 18) : parseUnits('1', 18)).toString(),
      );
      const vaultType = poolType === CreatePoolType.STABLESWAP ? VaultType.Amplified : VaultType.WeightedVolatile;
      const chainInterface = chainCCIs.get(chainId);
      if (!chainInterface) {
        throw new Error(`Invalid chain interface for ${chainId}`);
      }
      const vaultName = getVaultName();
      const deployVaultOptions: DeployVaultOptions = {
        vaultType,
        assets: assets.map((asset) => asset.address),
        initialBalances: amounts,
        weights,
        amplification,
        vaultFee: BigInt(parseUnits((poolFee / 100).toString(), 18).toString()),
        name: vaultName,
        chainInterface,
        symbol: vaultName,
      };
      const assetAmountMap = new Map<string, number>();
      const assetValues = new Map<string, number>();
      assets.forEach((asset) => {
        const assetKey = getAssetKey({ address: asset.address, chainId: asset.chainId.toString() });
        assetAmountMap.set(asset.symbol, assetAmounts.get(assetKey) || 0);
        assetValues.set(asset.symbol, depositValueMap.get(assetKey) || 0);
      });
      const deployVaultRequest: DeployVaultRequest = {
        deployOptions: deployVaultOptions,
        chainId,
        requestStatus: PoolActionState.Inactive,
        assetAmounts: assetAmountMap,
        assets,
        assetValues,
      };
      deployVaultRequests.set(chainId, deployVaultRequest);
    }

    return deployVaultRequests;
  };

  const prepareSetChannelRequests = async () => {
    if (!ambVersion) {
      throw Error('AmbVersion is not set');
    }
    const requests = new Map<string, SetVaultChannelsRequest[]>();

    for (const chainId of selectedChains) {
      const chainRequests = [];
      const otherChains = selectedChains.filter((c) => c !== chainId);
      for (const otherChainId of otherChains) {
        // @TODO: fetch this from the API
        const channelId = getChannelIdentifierOrThrow(ambVersion, chainId, otherChainId);
        const setChannelRequest: SetVaultChannelsRequest = {
          requestStatus: PoolActionState.Inactive,
          chainId: chainId,
          originVaultAddress: undefined,
          destinationVaultAddress: undefined,
          channelId,
          isActive: true,
        };

        chainRequests.push(setChannelRequest);
      }

      requests.set(chainId, chainRequests);
    }
    return requests;
  };

  const clearSavedPoolState = async () => {
    await db.poolCreateConfig.clear();
    setHasSavedPoolCreation(false);
  };

  const savePoolCreateState = async (address?: string) => {
    try {
      if (!address) {
        return;
      }
      if (assets.size > 0) {
        const poolCreateState: PoolCreateConfig = {
          assets: Array.from(assets.entries()),
          assetAmounts: Array.from(assetAmounts.entries()),
          assetWeights: Array.from(assetWeights.entries()),
          poolType,
          interopProtocol,
        };
        await db.poolCreateConfig.setItem(address, poolCreateState);
        runInAction(() => {
          poolStore.hasSavedPoolCreateState = true;
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const startPoolCreation = async () => {
    const poolCreateRequests: PoolCreateRequest[] = [];

    // prepare approve requests
    const approvalRequestsByChain = await prepareApproveRequests();

    // prepare deposit requests
    const deployVaultRequestByChain = await prepareVaultDeployRequests();

    const setChannelRequestsByChain = await prepareSetChannelRequests();

    // create chain actions
    for (const chainId of selectedChains) {
      const approvalRequests = approvalRequestsByChain.get(chainId) || [];
      const deployVaultRequest = deployVaultRequestByChain.get(chainId);
      const setChannelRequests = setChannelRequestsByChain.get(chainId) || [];
      if (deployVaultRequest) {
        poolCreateRequests.push({
          chainId,
          actionState: PoolActionState.Inactive,
          approvalRequests,
          deployVaultRequest,
          chainSwap: PoolActionState.Inactive,
          setChannelRequests,
        });
      }
    }

    runInAction(() => {
      poolStore.poolCreateRequests = poolCreateRequests;
      poolStore.poolCreateFeeDetails = feeDetails;
      poolStore.poolCreateTotalLiquidity = totalDepositValue;
      poolStore.poolCreateAssets = selectedTokens;
      poolStore.poolCreateType = poolType;
      poolStore.poolCreateFee = poolFee;
    });

    // clear saved pool state
    await clearSavedPoolState();
    // createPool();
    await poolStore.createPool();
  };

  const removeToken = (token: TokenInfo) => {
    const assetKey = getAssetKey({ address: token.address, chainId: token.chainId.toString() });
    assets.delete(assetKey);
    assetAmounts.delete(assetKey);
    assetWeights.delete(assetKey);
    setAssets(new Map(assets));
    setAssetAmounts(new Map(assetAmounts));
    setAssetWeights(new Map(assetWeights));

    if (assets.size === 0) {
      setIsInitialized(false);
    }
  };

  const addToken = (_chainId: string, token: TokenInfo) => {
    const assetKey = getAssetKey({ address: token.address, chainId: token.chainId.toString() });
    assets.set(assetKey, token);
    setIsInitialized(true);
    setAssets(new Map(assets));
  };

  const setTokenWeight = (token: TokenInfo, weight?: number) => {
    const assetKey = getAssetKey({ address: token.address, chainId: token.chainId.toString() });
    if (!weight) {
      assetWeights.delete(assetKey);
    } else {
      assetWeights.set(assetKey, weight);
    }

    setAssetWeights(new Map(assetWeights));
  };

  const setTokenAmount = (token: TokenInfo, value: number) => {
    const assetKey = getAssetKey({ address: token.address, chainId: token.chainId.toString() });
    assetAmounts.set(assetKey, value);
    setAssetAmounts(new Map(assetAmounts));
  };

  const fetchChainCCIs = async () => {
    for (const chainId of selectedChains) {
      if (!chainCCIs.has(chainId)) {
        if (!ambVersion) return;
        // TODO: there is more than one cci per chain, this map needs to be revised
        const cci = await poolStore.fetchCCI(chainId, ambVersion as unknown as CCIVersion);
        if (cci) {
          chainCCIs.set(chainId, cci);
        } else {
          const chain = getChainConfig(chainId);
          console.info(`CCI not found for chain ${chain.name} and version ${ambVersion}`);
        }
        setChainCCIs(new Map(chainCCIs));
      }
    }
  };

  const fetchChainAddresses = async () => {
    for (const chainId of selectedChains) {
      if (!chainVaultFactoryAddresses.has(chainId)) {
        const address = await poolStore.fetchVaultFactoryAddress(chainId);
        chainVaultFactoryAddresses.set(chainId, address);
        setChainVaultFactoryAddresses(new Map(chainVaultFactoryAddresses));
      }
    }
  };

  const updateDepositValueMap = async () => {
    const depositValueMap = new Map<string, number>();

    for (const asset of assets.values()) {
      const tokenPrice = catalystStore.getPrice(asset);
      const assetKey = getAssetKey({ address: asset.address, chainId: asset.chainId.toString() });
      const amount = assetAmounts.get(assetKey) || 0;
      if (amount && tokenPrice) {
        const value = tokenPrice * amount;
        depositValueMap.set(assetKey, value);
      }
    }
    setDepositValueMap(new Map(depositValueMap));
  };

  const withdrawFunds = async () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Withdraw;
      poolStore.withdrawFromIncompletePool();
    });
  };

  const resumePoolCreation = async () => {
    poolStore.createPool();
  };

  const showResumePoolCreation = () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Paused;
    });
  };

  const computeAmplifiedAssetWeights = () => {
    const computedAssetWeights = new Map<string, number>();

    const allAssets = Array.from(assets.values());

    const tokenWithMostDecimals = allAssets.reduce((prev, current) => {
      return prev.decimals > current.decimals ? prev : current;
    });

    const tokenWithMostDecimalsKey = getAssetKey({
      address: tokenWithMostDecimals.address,
      chainId: tokenWithMostDecimals.chainId.toString(),
    });
    computedAssetWeights.set(tokenWithMostDecimalsKey, 1);
    const otherTokens = allAssets.filter((token) => token !== tokenWithMostDecimals);
    for (const token of otherTokens) {
      const assetKey = getAssetKey({ address: token.address, chainId: token.chainId.toString() });
      const weight = tokenWithMostDecimals.decimals / token.decimals;
      computedAssetWeights.set(assetKey, weight);
    }
    return computedAssetWeights;
  };

  const computeAssetWeights = (assets: TokenInfo[]) => {
    const computedAssetWeights = poolType === CreatePoolType.STABLESWAP ? computeAmplifiedAssetWeights() : assetWeights;

    return assets.map((asset) => {
      const assetKey = getAssetKey({
        address: asset.address,
        chainId: asset.chainId.toString(),
      });
      const weight = computedAssetWeights.get(assetKey) || 0;
      return toUnits(weight / 100, 18);
    });
  };

  useEffect(() => {
    const loadSavedPoolCreateState = async () => {
      const address = walletStore.address;
      if (!address) {
        return;
      }
      const state = await db.poolCreateConfig.getItem<PoolCreateConfig>(address);
      if (state) {
        const { assets, assetAmounts, assetWeights, poolType, interopProtocol } = state;
        setHasSavedPoolCreation(true);
        setAssets(assets.length > 0 ? new Map(assets) : new Map());
        setAssetAmounts(assetAmounts.length > 0 ? new Map(assetAmounts) : new Map());
        setAssetWeights(assetWeights.length > 0 ? new Map(assetWeights) : new Map());
        setPoolType(poolType);
        setInteropProtocol(interopProtocol);
        setIsInitialized(true);
      }
    };
    loadSavedPoolCreateState();
  }, []);

  useEffect(() => {
    updateDepositValueMap();
  }, [assetAmounts]);

  useEffect(() => {
    const latestData = async () => {
      // get all chains
      await fetchChainAddresses();
      await fetchAmb();
      await fetchTokenPrices();
    };
    latestData();
  }, [assets]);

  useEffect(() => {
    fetchBalances();
  }, [tokenPrices]);

  useEffect(() => {
    checkIfPoolExists();
  }, [assets, assetWeights, poolFee, poolType]);

  useEffect(() => {
    fetchFeeDetails();
    fetchChainCCIs();
  }, [ambVersion, assets]);

  useEffect(() => {
    const newWeights = balanceAssetWeights();
    setAssetWeights(newWeights);
  }, [assets, assetWeightsLock]);

  const balanceAssetWeights = useCallback(() => {
    // automatically set asset weights if asset is added
    // every new asset weight is auto set to average weight roundDown(unlockedWeight / noOfUnlockedAssets)
    // if asset is last, add remainder to assetWeight
    const unlockedAssetsKeys = Array.from(assets.keys()).filter((assetKey) => !assetWeightsLock.has(assetKey));
    const lockedAssetsKeys = Array.from(assets.keys()).filter((assetKey) => assetWeightsLock.has(assetKey));
    const totalLockedWeight = lockedAssetsKeys.reduce((acc, key) => {
      const weight = assetWeights.get(key) || 0;
      return weight + acc;
    }, 0);

    // unlocked weight would be 100 - totalLockedWeight
    const unlockedWeight = 100 - totalLockedWeight;
    const tempAssetWeight = new Map(assetWeights);
    for (const [index, assetKey] of unlockedAssetsKeys.entries()) {
      let weight = Math.trunc(unlockedWeight / unlockedAssetsKeys.length);
      if (index === unlockedAssetsKeys.length - 1 && unlockedWeight % unlockedAssetsKeys.length) {
        const remainder = unlockedWeight % unlockedAssetsKeys.length;
        weight += remainder;
      }
      if (weight < 0 || weight > 100) {
        weight = 0;
      }
      tempAssetWeight.set(assetKey, weight);
    }
    return tempAssetWeight;
  }, [assets, assetWeightsLock]);

  useEffect(() => {
    const userAddress = walletStore.address;
    if (!userAddress) {
      return;
    }
    savePoolCreateState(userAddress);
  }, [
    assets,
    assetAmounts,
    assetWeights,
    assetBalances,
    gasTokenBalances,
    interopProtocol,
    poolType,
    walletStore.address,
  ]);

  useEffect(() => {
    const errors: Error[] = [];
    Array.from(assetAmounts.entries()).forEach(([assetKey, amount]) => {
      const balance = assetBalances.get(assetKey);
      const asset = assets.get(assetKey);
      if (!balance || !asset) {
        return;
      }
      const chainDetails = CatalystNetwork.getCatalystNetwork(asset.chainId.toString()).config;
      const balanceAmount = formatBalance(balance.amount, balance.decimals);
      if (!(amount > 0 && amount <= balanceAmount)) {
        errors.push({
          message: `Insufficient balance for ${asset.symbol} on ${chainDetails.name}`,
          name: 'Insfficient balance',
        });
      }
    });
    const liquidityError = totalDepositValue < 1;

    if (liquidityError) {
      errors.push({
        message: 'Total Liquidity is less than $1',
        name: 'Low Liquidity',
      });
    }

    if (totalTokenWeight > 100) {
      errors.push({
        message: `Total token weight (${totalTokenWeight}) is > 100`,
        name: 'Token weights greater than 100',
      });
    }

    setErrors(errors);
  }, [assetAmounts, assetBalances, assets, totalTokenWeight]);

  const fetchFeeDetails = useCallback(async () => {
    if (!ambVersion) {
      return;
    }
    const gasData = await catalystStore.getGasFeeData(selectedChains);
    const feeDetails = getPoolCreateFeeDetails({
      selectedChains,
      gasData,
      gasTokenPrices: tokenPrices,
    });
    setFeeDetails(feeDetails);
  }, [tokenPrices, selectedChains, ambVersion, assetWeights]);

  const fetchBalances = useCallback(async () => {
    const newAssetBalance = new Map<string, TokenBalance>(assetBalances);
    const newGasTokenBalance = new Map<string, TokenBalance>(gasTokenBalances);
    const assetsArr = Array.from(assets.values());
    const feeData = await catalystStore.getGasFeeData(selectedChains);

    for (const asset of assetsArr) {
      const assetKey = getAssetKey({
        address: asset.address,
        chainId: asset.chainId.toString(),
      });

      const chainId = asset.chainId.toString();
      const balance = walletStore.getBalance(asset.chainId.toString(), asset.address);
      const gasTokenBalance = walletStore.getBalance(asset.chainId.toString(), GAS_TOKEN_IDENTIFIER);
      const maxGasFee = getMaxGasFee(feeData[chainId]);

      // get the gas price for the chain, and subtract the gas cost from the gas token balance
      const totalGasCost = maxGasFee;

      const maxGasTokenBalance = gasTokenBalance.amount - totalGasCost;

      // if the gas token balance is negative, set it to 0
      gasTokenBalance.amount = maxGasTokenBalance < 0n ? 0n : maxGasTokenBalance;

      newAssetBalance.set(assetKey, balance);
      newGasTokenBalance.set(assetKey, gasTokenBalance);
    }
    setAssetBalances(newAssetBalance);
    setGasTokenBalances(newGasTokenBalance);
  }, [assets, tokenPrices]);

  const fetchAmb = useCallback(async () => {
    const recommendedAmbVersion = await catalystStore.getSuggestedAmbVersion(selectedChains).catch((e) => {
      console.error('Error fetching AMB version ->>', e);
      return Promise.resolve(null);
    });
    setAmbVersion(recommendedAmbVersion);
  }, [assets]);

  const fetchTokenPrices = useCallback(async () => {
    const tokenInfoPromises = selectedTokens.map((asset) => {
      return catalystStore.getToken(asset.chainId.toString(), asset.address);
    });
    const gasTokenInfoPromises = Array.from(new Set(selectedTokens.map((asset) => asset.chainId.toString()))).map(
      (chainId) => {
        return catalystStore.getToken(chainId, GAS_TOKEN_IDENTIFIER);
      },
    );
    const tokenInfos = await Promise.all([...tokenInfoPromises, ...gasTokenInfoPromises]);
    const tokenPrices = tokenInfos.map((tokenInfo) => {
      return catalystStore.getPrice(tokenInfo);
    });
    const tokenPriceMap = new Map<string, number>();
    tokenInfos.forEach((tokenInfo, index) => {
      tokenPriceMap.set(
        getAssetKey({
          address: tokenInfo.address,
          chainId: tokenInfo.chainId.toString(),
        }),
        tokenPrices[index],
      );
    });
    setTokenPrices(tokenPriceMap);
  }, [selectedTokens]);

  const checkIfPoolExists = useCallback(async () => {
    let vaultType: VaultTypeAPI;
    switch (poolType) {
      case CreatePoolType.CLASSIC:
        vaultType = VaultTypeAPI.WEIGHTED_VOLATILE;
        break;
      case CreatePoolType.STABLESWAP:
        vaultType = VaultTypeAPI.AMPLIFIED;
        break;
      default:
        vaultType = VaultTypeAPI.WEIGHTED_VOLATILE;
    }
    const isPoolSimilar = await poolStore
      .checkIfPoolExists({
        assets: selectedTokens.map((asset) => {
          const assetKey = getAssetKey({
            address: asset.address,
            chainId: asset.chainId.toString(),
          });
          return {
            chainId: asset.chainId.toString(),
            address: asset.address,
            weight: assetWeights.get(assetKey) || 0,
          };
        }),
        poolType: vaultType,
        poolFee,
      })
      .catch((e) => console.error('Error checking if pool exists ->> ', e));
    setIsSimilarToExistingPool(Boolean(isPoolSimilar));
  }, [poolFee, selectedTokens, poolType, assetWeights]);

  const getVaultName = useCallback(() => {
    const name = ambVersion?.toString();

    const chainNames = selectedChains.reduce((acc, chain) => {
      const chainName = CatalystNetwork.getCatalystNetwork(chain).config.name;
      return acc + chainName.replace(' ', '');
    }, '');

    return name + chainNames;
  }, [selectedChains]);

  const onLockAssetWeight = (assetKey: string) => {
    setAssetWeightLock(new Map(assetWeightsLock).set(assetKey, true));
  };

  const onUnlockAssetWeight = (assetKey: string) => {
    assetWeightsLock.delete(assetKey);
    setAssetWeightLock(new Map(assetWeightsLock));
  };

  return {
    poolType,
    isInitialized,
    selectTokenModalOpen,
    selectedTokensAndChain,
    selectedTokenWeights,
    canClickCreate,
    hasSelectedMaxTokens,
    interopProtocol,
    selectedChains,
    selectedTokens,
    totalTokenWeight,
    totalDepositValue,
    hasSavedPoolCreation,
    assetWeights,
    assetWeightsLock,
    assetAmounts,
    poolFee,
    poolCreateFormStep,
    feeDetails,
    gasTokenBalances,
    assetBalances,
    isSimilarToExistingPool,
    selectedTokensBalanceValue,
    errors,
    handlers: {
      setTokenAmount,
      setPoolType: (newType: CreatePoolType) => setPoolType(newType),
      cancelCreate,
      startPoolCreation,
      removeToken,
      addToken,
      openSelectTokenModal: () => setSelectTokenModalOpen(true),
      closeSelectTokenModal: () => setSelectTokenModalOpen(false),
      setTokenWeight,
      setInteropProtocol,
      completePoolCreate,
      resetPoolCreate,
      withdrawFunds,
      resumePoolCreation,
      showResumePoolCreation,
      setPoolFee,
      setPoolCreateFormStep,
      onLockAssetWeight,
      onUnlockAssetWeight,
    },
  };
}
