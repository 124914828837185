import { Pool, PriceLookup } from '@catalabs/catalyst-api-client';
import { ArbitraryMessagingBridge } from '@catalabs/catalyst-channel-lists';
import { getToken, getTokenList } from '@catalabs/catalyst-token-lists';
import { TokenInfo } from '@uniswap/token-lists';
import { makeAutoObservable, runInAction } from 'mobx';

import { CatalystNetwork } from '~/config';
import { RootStore, Store } from '~/modules/common';
import { db } from '~/modules/common/db';
import { CciFeeData, ChainFeeData, ChainGasFeeData } from '~/modules/common/interfaces';

/**
 * @TODO
 * This store is very poorly done,
 * - move all pool deposit and withdraw logic to pool store
 * - generallly clean up the store
 * - add documentation to methods
 */
export class CatalystStore implements Store {
  prices: PriceLookup = {};

  catalystPools: Map<string, Pool[]> = new Map();

  tokens: TokenInfo[] | undefined;

  targetPoolSize = 1;

  constructor(private store: RootStore) {
    makeAutoObservable(this);
  }

  areAllZero(numbers: bigint[]): boolean {
    return numbers.every((num) => num === 0n);
  }

  async updatePrices() {
    try {
      const prices = await this.store.client.getAssetPrices();
      runInAction(() => {
        this.prices = prices;
      });
    } catch (err) {
      console.error(err);
    }
  }

  getPrice(token?: TokenInfo): number {
    if (!token) {
      return 0;
    }
    const maybeNetwork = this.prices[token.chainId];
    if (!maybeNetwork) {
      return 0;
    }
    const maybePrice = maybeNetwork[token.address];
    if (!maybePrice) {
      return 0;
    }
    return maybePrice;
  }

  // This is for testing purposes, can be removed later
  async revokeToken(token: string, spender: string) {
    const { sourceNetwork } = this.store.swap;
    const { address } = this.store.wallet;

    if (!sourceNetwork || !address) {
      return;
    }

    const catalyst = CatalystNetwork.getCatalystNetwork(sourceNetwork);
    const { sdk } = catalyst;

    await sdk.revoke(token, spender);
  }

  getTokenList(chainId: string): TokenInfo[] {
    try {
      const tokenList = getTokenList(chainId.toString());
      runInAction(() => {
        this.tokens = tokenList.tokens;
      });
      return tokenList.tokens;
    } catch (error) {
      console.error('Failed to fetch data', error);
      return [];
    }
  }

  async getSavedTokenList(chainId: string): Promise<TokenInfo[]> {
    try {
      const allSavedTokens: TokenInfo[] = [];
      await db.tokens.iterate((value) => {
        allSavedTokens.push(value as TokenInfo);
      });
      const savedTokens = allSavedTokens.filter((t) => t.chainId === parseInt(chainId));
      runInAction(() => {
        this.tokens?.push(...savedTokens);
      });
      return savedTokens;
    } catch (error) {
      console.error('Failed to fetch data', error);
      return [];
    }
  }

  async getToken(chainId: string, tokenAddress: string): Promise<TokenInfo> {
    let tokenInfo: TokenInfo | undefined;

    const tokenList = this.tokens ?? getTokenList(chainId).tokens;

    // get the token info from the store
    tokenInfo = tokenList.find((t) => t.chainId.toString() === chainId && t.address === tokenAddress);

    // if the token is not in the store, fetch it from the token list
    if (!tokenInfo) {
      try {
        return getToken(chainId, tokenAddress);
      } catch (error) {
        // if it doesn't exist in the token list, fetch it from the API
        const token = await this.store.swap.getTokenInfo(chainId, tokenAddress);

        if (token) {
          tokenInfo = {
            ...token,
            chainId: parseInt(chainId),
          };
          // cache the token locally if it exists in the chain token list
          this.saveTokenLocally(tokenInfo);
          runInAction(() => {
            if (tokenInfo) this.tokens?.push(tokenInfo);
          });
        }
      }
    }

    if (!tokenInfo) {
      throw new Error(
        `Unexpected missing token definition! Please report to the Catalyst Team! chainId: ${chainId}, tokenAddress: ${tokenAddress}`,
      );
    }

    return tokenInfo;
  }

  saveTokenLocally(token: TokenInfo) {
    const key = `${token.chainId}-${token.address}`;
    db.tokens.setItem(key, token);
  }

  async getGasFeeData(fromChainIds: string[]): Promise<ChainGasFeeData> {
    const gasFeeDataByChainId: ChainGasFeeData = {};

    try {
      // Fetch gas fee data from API
      const gasFeeDataFromApi = await this.store.client.getFeeData(fromChainIds);

      for (const fromChainId in gasFeeDataFromApi) {
        const gasFeeData = gasFeeDataFromApi[fromChainId];
        gasFeeDataByChainId[fromChainId] = {
          gasPrice: BigInt(gasFeeData.gasPrice),
          maxFeePerGas: BigInt(gasFeeData.maxFeePerGas),
          maxPriorityFeePerGas: BigInt(gasFeeData.maxPriorityFeePerGas),
        };
      }
    } catch (err) {
      console.warn('Failed to fetch gas fee data from API. Reverting to SDK.');

      for (const fromChainId of fromChainIds) {
        try {
          const { sdk } = CatalystNetwork.getCatalystNetwork(fromChainId);
          // Fallback to SDK if API call failed
          const gasFeeData = await sdk.getGasFeeData();
          gasFeeDataByChainId[fromChainId] = {
            gasPrice: gasFeeData.gasPrice ?? 0n,
            maxFeePerGas: gasFeeData.maxFeePerGas ?? 0n,
            maxPriorityFeePerGas: gasFeeData.maxPriorityFeePerGas ?? 0n,
          };
        } catch (err) {
          throw new Error(`Failed to fetch gas fee data for chain ID ${fromChainId}.`);
        }
      }
    }

    return gasFeeDataByChainId;
  }

  async getFeeData(
    arbitraryMessagingBridge: ArbitraryMessagingBridge,
    fromChainIds: string[],
    toChainIds?: string[],
  ): Promise<ChainFeeData> {
    /* 
      - If toChainIds is not provided, use fromChainIds as both source and destination.
      - This allows us to calculate messageVerifyGasCost for all pairs of the given chain IDs.
    */
    if (!toChainIds) {
      toChainIds = [...fromChainIds];
    }

    const feeDataByChainId: ChainFeeData = {};

    const gasFeeDataByChainId = await this.getGasFeeData(fromChainIds);
    for (const fromChainId of fromChainIds) {
      const gasFeeData = gasFeeDataByChainId[fromChainId];
      feeDataByChainId[fromChainId] = {
        ...gasFeeData,
        cciFeeData: {},
      };
    }

    try {
      // Fetch CCI fee data from API
      const cciFeeDataByChainId = await this.store.client.gas.getCciFeeData(
        arbitraryMessagingBridge,
        fromChainIds,
        toChainIds,
      );

      for (const fromChainId of fromChainIds) {
        const cciFeeData = cciFeeDataByChainId[fromChainId];
        feeDataByChainId[fromChainId].cciFeeData = cciFeeData;
      }
    } catch (err) {
      console.warn('Failed to fetch CCI fee data from API. Reverting to SDK.');

      // Fallback to SDK as API call failed
      for (const fromChainId of fromChainIds) {
        try {
          const { sdk } = CatalystNetwork.getCatalystNetwork(fromChainId);
          const cciFeeDataByVersion = await sdk.getCciFeeData(toChainIds);

          const cciFeeData: CciFeeData = {};
          for (const toChainId of toChainIds) {
            const { asset, amount } = cciFeeDataByVersion[arbitraryMessagingBridge][toChainId].messageVerifyGasCost;
            cciFeeData[toChainId] = {
              messageVerifyGasCost: {
                asset,
                amount: amount.toString(),
              },
            };
            feeDataByChainId[fromChainId].cciFeeData = cciFeeData;
          }
        } catch (err) {
          throw new Error(
            `Failed to fetch CCI fee data for source chain ID ${fromChainId}, destination chain IDs ${toChainIds}.`,
          );
        }
      }
    }

    return feeDataByChainId;
  }

  async getSuggestedAmbVersion(chains: string[]) {
    const response = await this.store.client.chains.getChainCompatibility(chains);
    const ambVersion = response.arbitraryMessagingBridge;
    return ambVersion;
  }

  async reset() {
    runInAction(() => {
      this.tokens = undefined;
      this.catalystPools = new Map();
    });
  }
}
