import { FeeDetail } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { formatUnits } from 'ethers';

import { CatalystNetwork } from '~/config';
import { ChainGasFeeData } from '~/modules/common';

import { getAssetKey } from './pools.utils';

export const getPoolCreateFeeDetails = ({
  selectedChains,
  gasData,
  gasTokenPrices,
}: {
  selectedChains: string[];
  gasData: ChainGasFeeData;
  gasTokenPrices: Map<string, number>;
}) => {
  const feeDetails: FeeDetail[] = [];
  let executionFee: number = 0;

  for (const chainId of selectedChains) {
    const chainDetails = CatalystNetwork.getCatalystNetwork(chainId);
    const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = gasData[chainId];
    const supportsEip1559 = maxPriorityFeePerGas !== null || maxPriorityFeePerGas !== 0n;
    const feeInGas = supportsEip1559 ? maxFeePerGas : gasPrice;

    const gasTokenPrice =
      gasTokenPrices.get(
        getAssetKey({
          address: GAS_TOKEN_IDENTIFIER,
          chainId: chainDetails.config.chainId,
        }),
      ) || 1;
    const totalGasPrice = BigInt('190000') * BigInt(feeInGas);
    const feeinUSD = Number(formatUnits(totalGasPrice)) * gasTokenPrice;
    executionFee += feeinUSD;
  }

  /**
   * All fees are multiplied by 3
   * 1. Vault creation and Initial liquidity txn fee
   * 2. Set AMB txn fee
   * 3. Finish Setup txn fee
   *
   */
  feeDetails.push({
    amount: executionFee * 3,
    currency: 'USD',
    name: 'Execution Fee',
    value: executionFee * 3,
  } as FeeDetail);
  return feeDetails;
};
