import './PoolCreateForm.css';

import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Accordion from '@radix-ui/react-accordion';
import * as Popover from '@radix-ui/react-popover';
import { TokenInfo } from '@uniswap/token-lists';
import { useState } from 'react';

import { TokenBalance } from '~/config';
import { Button, formatCurrency, formatPercentage, Input, TokenDisplay } from '~/modules/common';

import { POOL_CREATE_FEE_OPTIONS } from '../../constants';
import { CreatePoolType, PoolCreateFormStep } from '../../enums';
import { getPoolTypeText } from '../../utils';
import TokenAmountInput from '../TokenAmountInput';
import TokenWeightInput from '../TokenWeightInput';
import TokenWeightInputLoader from '../TokenWeightInputLoader';

interface PoolCreateFormProps {
  poolType: CreatePoolType;
  selectedTokensAndChain: [string, TokenInfo][];
  assetWeights: Map<string, number>;
  totalTokenWeight: number;
  onChangeTokenWeight: (token: TokenInfo, weight?: number) => void;
  onSelectPoolType: (poolType: CreatePoolType) => void;
  onClickRemoveToken: (token: TokenInfo) => void;
  onClickOpenSelectTokenModal: () => void;
  hasSelectedMaxTokens: boolean;
  onChangeTokenDepositAmount: (token: TokenInfo, amount: number) => void;
  assetAmounts: Map<string, number>;
  poolFee: number;
  onChangePoolFee: (fee: number) => void;
  totalLiquidity: number;
  poolCreateStep: PoolCreateFormStep;
  setPoolCreateStep: (step: PoolCreateFormStep) => void;
  isSimilarToExistingPool: boolean;
  selectedTokenTotalBalance: number;
  selectedTokenBalances: Map<string, TokenBalance>;
  onClickLockWeight?: (assetKey: string) => void;
  onClickUnlockWeight?: (assetKey: string) => void;
  assetweightLocked: Map<string, boolean>;
}

export const PoolCreateForm = ({
  poolType,
  onSelectPoolType,
  onClickOpenSelectTokenModal,
  selectedTokensAndChain,
  assetWeights,
  onChangeTokenWeight,
  onClickRemoveToken,
  hasSelectedMaxTokens,
  assetAmounts,
  onChangeTokenDepositAmount,
  poolFee,
  onChangePoolFee,
  totalLiquidity,
  poolCreateStep = PoolCreateFormStep.Type,
  setPoolCreateStep,
  isSimilarToExistingPool,
  selectedTokenTotalBalance,
  selectedTokenBalances,
  onClickLockWeight,
  onClickUnlockWeight,
  assetweightLocked,
}: PoolCreateFormProps) => {
  const [isCustomFeePopOverOpen, setIscustomFeePopOverOpen] = useState(false);

  const poolFeeIsCustom = !Boolean(POOL_CREATE_FEE_OPTIONS.some((option) => option.value === poolFee));

  const handleCustomFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value);
    if (number > 100 || number < 0 || isNaN(number)) return;
    onChangePoolFee(number);
  };

  const handleCustomFeeSave = () => {
    setIscustomFeePopOverOpen(false);
  };

  const totalWeights = Array.from(assetWeights.values()).reduce((acc, weight) => acc + weight, 0);

  return (
    <Accordion.Root type="single" defaultValue={PoolCreateFormStep.Type} value={poolCreateStep}>
      {/* Pool Type Section Starts*/}
      <Accordion.Item value={PoolCreateFormStep.Type} className="accordion-item">
        <Accordion.Header className="accordion-header">
          <Accordion.Trigger className="accordion-trigger" onClick={() => setPoolCreateStep(PoolCreateFormStep.Type)}>
            <h3>Choose Pool Type</h3>
            <ChevronDownIcon className="accordion-chevron" aria-hidden />
          </Accordion.Trigger>
          {poolCreateStep !== PoolCreateFormStep.Type && (
            <div className="accordion-header-pill">{getPoolTypeText(poolType)}</div>
          )}
        </Accordion.Header>
        <Accordion.Content className="accordion-content">
          <div>
            <div className="flex flex-col gap-6 md:flex-row">
              <div
                className="pool-type-card"
                role="button"
                data-state={poolType === CreatePoolType.CLASSIC && 'selected'}
                onClick={() => poolType !== CreatePoolType.CLASSIC && onSelectPoolType(CreatePoolType.CLASSIC)}
              >
                <h5>Classic</h5>
                <p>Best for assets with different value</p>
                <CheckCircleIcon className="check-icon" aria-hidden />
              </div>
              <div
                className="pool-type-card"
                role="button"
                data-state={poolType === CreatePoolType.STABLESWAP && 'selected'}
                onClick={() => poolType !== CreatePoolType.STABLESWAP && onSelectPoolType(CreatePoolType.STABLESWAP)}
              >
                <h5>Stable</h5>
                <p>Best for assets with same value.</p>
                <CheckCircleIcon className="check-icon" aria-hidden />
              </div>
            </div>
            <Button
              size="small"
              onClick={() => {
                setPoolCreateStep(PoolCreateFormStep.TokensAndWeights);
              }}
            >
              Continue
            </Button>
          </div>
        </Accordion.Content>
      </Accordion.Item>
      {/* Pool Type Section Ends */}

      {/* Pool Tokens and Weights Section Starts */}
      <Accordion.Item value={PoolCreateFormStep.TokensAndWeights} className="accordion-item">
        <Accordion.Header className="accordion-header">
          <Accordion.Trigger
            className="accordion-trigger"
            onClick={() => setPoolCreateStep(PoolCreateFormStep.TokensAndWeights)}
          >
            <h3>Select tokens and set weights</h3>
            <ChevronDownIcon className="accordion-chevron" aria-hidden />
          </Accordion.Trigger>
          {poolCreateStep !== PoolCreateFormStep.TokensAndWeights && (
            <div className="gap2 flex">
              {selectedTokensAndChain.map(([assetKey, token]) => (
                <TokenDisplay
                  chainId={token.chainId.toString()}
                  token={token.address}
                  key={assetKey}
                  size="sm"
                  showSymbol={false}
                />
              ))}
            </div>
          )}
        </Accordion.Header>
        <Accordion.Content className="accordion-content">
          {(totalWeights > 100 || totalWeights < 0) && (
            <div className="error-message my-2">
              Total weights of tokens is ({totalWeights}). It should be equal to 100
            </div>
          )}
          <div className="flex flex-col gap-3">
            {selectedTokensAndChain.length < 1 ? (
              <>
                <TokenWeightInputLoader onClick={onClickOpenSelectTokenModal} />
                <TokenWeightInputLoader onClick={onClickOpenSelectTokenModal} />
              </>
            ) : (
              selectedTokensAndChain.map(([assetKey, token]) => (
                <TokenWeightInput
                  key={assetKey}
                  token={token}
                  chainId={token.chainId}
                  onChange={onChangeTokenWeight}
                  onRemoveToken={onClickRemoveToken}
                  onClick={onClickOpenSelectTokenModal}
                  value={assetWeights.get(assetKey)}
                  isRemoveDisabled={selectedTokensAndChain.length < 3}
                  isLockDisabled={selectedTokensAndChain.length < 3}
                  onClickLockWeight={onClickLockWeight}
                  onClickUnlockWeight={onClickUnlockWeight}
                  isLocked={assetweightLocked.has(assetKey)}
                />
              ))
            )}
            {!hasSelectedMaxTokens && selectedTokensAndChain.length > 0 ? (
              <TokenWeightInput.Loader
                onClick={onClickOpenSelectTokenModal}
                nextTokenNumber={selectedTokensAndChain.length + 1}
                isAddingToken
              />
            ) : null}

            {selectedTokensAndChain.length >= 2 && (
              <Button
                size="small"
                onClick={() => {
                  setPoolCreateStep(PoolCreateFormStep.Fee);
                }}
              >
                Continue
              </Button>
            )}
          </div>
        </Accordion.Content>
      </Accordion.Item>
      {/* Pool Tokens and Weights Section Ends */}

      {/* Select Fee Section Starts */}
      <Accordion.Item value={PoolCreateFormStep.Fee} className="accordion-item">
        <Accordion.Header className="accordion-header">
          <Accordion.Trigger className="accordion-trigger" onClick={() => setPoolCreateStep(PoolCreateFormStep.Fee)}>
            <h3>Select Fee</h3>
            <ChevronDownIcon className="accordion-chevron" aria-hidden />
          </Accordion.Trigger>
          {poolCreateStep !== PoolCreateFormStep.Fee && poolFee ? (
            <div className="accordion-header-pill">{formatPercentage(poolFee)}</div>
          ) : null}
        </Accordion.Header>
        <Accordion.Content className="accordion-content">
          <div>
            <div className="flex flex-col gap-6 md:flex-row">
              {POOL_CREATE_FEE_OPTIONS.map((option) => (
                <div
                  key={`fee-option-${option.value}`}
                  className="pool-fee-card"
                  role="button"
                  data-state={poolFee === option.value ? 'selected' : 'unselected'}
                  onClick={() => onChangePoolFee(option.value)}
                >
                  <h5>{formatPercentage(option.value)}</h5>
                  <p>{option.description}</p>
                  <CheckCircleIcon className="check-icon" aria-hidden />
                </div>
              ))}
              <Popover.Root open={isCustomFeePopOverOpen} onOpenChange={(val) => setIscustomFeePopOverOpen(val)}>
                <Popover.Trigger
                  onClick={() => setIscustomFeePopOverOpen(true)}
                  className="pool-fee-card"
                  data-state={poolFeeIsCustom && poolFee ? 'selected' : 'unselected'}
                >
                  <div className="header flex gap-2">
                    <h5>{poolFeeIsCustom && poolFee ? formatPercentage(poolFee) : 'Custom'}</h5>
                    <ChevronDownIcon className=" h-5 w-5" />
                  </div>
                  <p>Customised fee</p>
                  <CheckCircleIcon className="check-icon" aria-hidden />
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content side="top" className="pool-fee-custom-popover">
                    <div className="pool-fee-popover-content">
                      <h6>Custom Fee</h6>
                      <Input
                        numeric
                        max={100}
                        min={0}
                        placeholder="0"
                        onChange={handleCustomFeeChange}
                        value={poolFee}
                      />
                      <Button className="w-full" size="large" onClick={handleCustomFeeSave}>
                        Save
                      </Button>
                    </div>
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>
            {isSimilarToExistingPool && (
              <div className="error-message">
                Similar pool already exists. You can still proceed with creating your pool, but you will need to cover
                the gas costs for its creation. Also, the liquidity will be divided, which may lead to your new pool
                being less profitable.
              </div>
            )}
            <Button
              size="small"
              onClick={() => {
                setPoolCreateStep(PoolCreateFormStep.Liquidity);
              }}
            >
              Continue
            </Button>
          </div>
        </Accordion.Content>
      </Accordion.Item>
      {/* Select Fee Section Ends */}

      {/* Add Liquidity Section Starts */}
      <Accordion.Item value={PoolCreateFormStep.Liquidity} className="accordion-item">
        <Accordion.Header className="accordion-header">
          <Accordion.Trigger
            className="accordion-trigger"
            onClick={() => setPoolCreateStep(PoolCreateFormStep.Liquidity)}
          >
            <h3>Add Liquidity</h3>
            <ChevronDownIcon className="accordion-chevron" aria-hidden />
          </Accordion.Trigger>
          {poolCreateStep !== PoolCreateFormStep.Liquidity && totalLiquidity ? (
            <div className="accordion-header-pill">{formatCurrency(totalLiquidity, 2)}</div>
          ) : null}
        </Accordion.Header>
        <Accordion.Content className="accordion-content">
          <div className=" flex flex-col gap-3">
            <div className="flex flex-col  gap-6">
              <div className="text-sm text-gray-500">A minimum of $20,000.00 of initial liquidity is recommended.</div>
              {selectedTokensAndChain.map(([assetKey, token]) => {
                const assetBalance = selectedTokenBalances.get(assetKey);
                return (
                  <TokenAmountInput
                    token={token}
                    balance={assetBalance}
                    onChange={(amount) => onChangeTokenDepositAmount(token, amount)}
                    disabled={false}
                    key={assetKey}
                    amount={assetAmounts.get(assetKey)}
                  />
                );
              })}
            </div>

            <div className="info-message">
              Based on your wallet balances, the maximum amount you can add to the pool is ~
              {formatCurrency(selectedTokenTotalBalance)}.
            </div>

            <Button
              size="small"
              onClick={() => {
                setPoolCreateStep(PoolCreateFormStep.ReadyToCreate);
              }}
            >
              Finish & Review
            </Button>
          </div>
        </Accordion.Content>
      </Accordion.Item>
      {/* Add Liquidity Section Ends */}
    </Accordion.Root>
  );
};
