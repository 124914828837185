import { Account, Pool } from '@catalabs/catalyst-api-client';
import { EvmSDK } from '@catalabs/catalyst-sdk';
import { ethers } from 'ethers';
import { makeAutoObservable, runInAction } from 'mobx';

import { CatalystNetwork, CatalystWallet, EVM_NETWORKS, Keplr, NetworkType, TokenBalance } from '~/config';
import { RootStore, Store } from '~/modules/common';
import { shortenAddress } from '~/modules/common/utils';
import { PortfolioStore } from '~/modules/portfolio';
import { TransactionsStore } from '~/modules/transactions/store';

/**
 * TODO:
 *  - setup a general wallet interface for user interactions
 *  - set up walk through process for
 *  - chain -> wallet -> connected
 *    or
 *  - wallet -> chain -> connected (i think this makes less sense)
 */
export class WalletStore implements Store {
  readonly transactions: TransactionsStore;
  readonly portfolio: PortfolioStore;

  private switchEvmNetwork?: (chainId: number) => Promise<boolean> = undefined;

  address?: string = undefined;
  wallet?: CatalystWallet;
  account?: Account;
  network?: CatalystNetwork;

  walletChainId?: number;

  tokens: Map<string, Map<string, TokenBalance>> = new Map();

  constructor(private store: RootStore) {
    makeAutoObservable(this);
    this.transactions = new TransactionsStore(store);
    this.portfolio = new PortfolioStore(store);
  }

  get isConnected() {
    return this.address !== undefined;
  }

  get displayAddress() {
    if (this.address) {
      return shortenAddress(this.address);
    }
    return '';
  }

  async connect(chainId: string) {
    const connected = await this.connectNetwork(chainId);
    if (connected) {
      await this.fetchAccount();
    }
  }

  async connectNetwork(chainId: string): Promise<boolean> {
    try {
      if (this.walletChainId?.toString() === chainId) {
        return true;
      }
      const network = CatalystNetwork.getCatalystNetwork(chainId);
      const { config } = network;

      let wallet: CatalystWallet;

      if (config.networkType === NetworkType.TinderMint) {
        wallet = new Keplr();

        const { address } = await wallet.connect(config);

        runInAction(() => {
          this.address = address;
          this.wallet = wallet;
          this.network = network;
        });

        return true;
      }
      if (this.switchEvmNetwork) {
        try {
          const response = await this.switchEvmNetwork(Number(chainId));
          return response;
        } catch (e) {
          console.error(e);
          return false;
        }
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  disconnect() {
    this.address = undefined;
    this.switchEvmNetwork = undefined;
    this.store.reset();
  }

  async fetchAccount(): Promise<void> {
    if (!this.address) {
      return;
    }
    try {
      const account = await this.store.client.accounts.getAccount(this.address);

      runInAction(() => {
        this.account = account;
      });
    } catch {
      // TODO: determine what best to do in error state
      console.error(`Unable to load account for ${this.address}`);
    }
  }

  async updatePoolBalances(pool: Pool): Promise<void> {
    const chainIds = Object.keys(pool.vaults);
    await this.updateChainBalances(chainIds);
  }

  async updateChainBalances(chainIds: string[]): Promise<void> {
    if (!this.address) {
      return;
    }

    try {
      const { balances } = await this.store.client.getBalances(this.address, chainIds);
      const tempTokenMap = new Map<string, Map<string, TokenBalance>>(this.tokens);
      await Promise.all(
        Object.entries(balances).map(async (e) => {
          const [chainId, chainBalances] = e;
          let chainMap = this.tokens.get(chainId);
          if (!chainMap) {
            chainMap = new Map<string, TokenBalance>();
          }
          Object.entries(chainBalances).forEach(async (e) => {
            const [token, balance] = e;
            const tokenInformation = await this.store.catalyst.getToken(chainId, token);
            if (!tokenInformation) return;
            chainMap?.set(token, {
              ...tokenInformation,
              amount: BigInt(balance),
            });
          });
          tempTokenMap.set(chainId, chainMap);
        }),
      );
      runInAction(() => {
        this.tokens = tempTokenMap;
      });
    } catch (e) {
      console.error(e);
    }
  }

  getBalance(chainId: string, address: string): TokenBalance {
    let maybeChainTokens = this.tokens.get(chainId);
    if (!maybeChainTokens) {
      maybeChainTokens = new Map();
      this.tokens.set(chainId, maybeChainTokens);
    }

    const maybeToken = maybeChainTokens.get(address);
    if (maybeToken) {
      //... create a new object to prevent mutation
      return { ...maybeToken };
    }
    this.updateChainBalances([chainId]);
    return {
      name: '',
      address: address,
      symbol: '',
      decimals: 18,
      amount: 0n,
    };
  }

  async syncEvmSigner(
    signer: ethers.JsonRpcSigner,
    switchNetworkAsync?: (chainId: number) => Promise<boolean>,
  ): Promise<void> {
    const network = await signer.provider?.getNetwork();

    if (!network) {
      return;
    }

    const { chainId } = network;
    const isSupportedChain = EVM_NETWORKS.map((net) => net.chain.id).includes(chainId.toString());

    if (!isSupportedChain) return;

    const sdk = CatalystNetwork.getCatalystNetwork(chainId.toString()).getSdk<EvmSDK>();
    await sdk.connectSigner(signer);

    if (sdk.address) {
      runInAction(() => {
        this.address = sdk.address;
      });
    }

    this.switchEvmNetwork = switchNetworkAsync;

    runInAction(() => {
      this.store.swap.sourceNetwork = chainId.toString();
    });
  }

  async signMessage(message: string): Promise<string> {
    if (!this.walletChainId) {
      throw new Error('No chain connected');
    }

    const chainId = this.walletChainId;

    await this.connectNetwork(chainId.toString());

    const sdk = CatalystNetwork.getCatalystNetwork(chainId.toString()).getSdk<EvmSDK>();
    const signature = await sdk.signMessage(message);

    return signature;
  }

  async signTypedData(
    domain: ethers.TypedDataDomain,
    types: Record<string, ethers.TypedDataField[]>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: Record<string, any>,
  ): Promise<string> {
    if (!this.walletChainId) {
      throw new Error('No chain connected');
    }

    const chainId = this.walletChainId;

    await this.connectNetwork(chainId.toString());

    const sdk = CatalystNetwork.getCatalystNetwork(chainId.toString()).getSdk<EvmSDK>();
    const signature = await sdk.signTypedData(domain, types, value);

    return signature;
  }

  async reset() {
    this.disconnect();
  }
}
