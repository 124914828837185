import { Pool } from '@catalabs/catalyst-api-client';
import { ArbitraryMessagingBridge } from '@catalabs/catalyst-channel-lists';

import { CreatePoolType } from '../enums';

export const POOL_BREAKDOWN_COLORS = [
  '#9061F9',
  '#FF5A1F',
  '#31C48D',
  '#3F83F8',
  '#FACA15',
  '#F05252',
  '#16BDCA',
  '#E74694',
];

export function createPoolGradient(pool: Pool): string {
  const { assets } = pool;
  const nodes: string[] = [];

  let currentDegrees = 0;
  for (let i = 0; i < assets.length; i++) {
    const assetDegrees = Math.floor((assets[i].weight * 360) / 100);
    const start = currentDegrees;
    const end = i < assets.length - 1 ? assetDegrees + currentDegrees - 10 : 360;
    nodes.push(`${POOL_BREAKDOWN_COLORS[i]} ${start}deg`);
    nodes.push(`${POOL_BREAKDOWN_COLORS[i]} ${end}deg`);
    currentDegrees += assetDegrees;
  }

  return `conic-gradient(${nodes.join(',')})`;
}

export function getAssetKey(asset: { address: string; chainId: string }): string {
  const { address, chainId } = asset;
  return [address, chainId].join('-');
}

export function getDepositText(allTokens: string[]): string {
  let depositText = '';
  if (allTokens.length === 1) {
    depositText = allTokens[0];
  } else if (allTokens.length === 2) {
    depositText = `${allTokens[0]} and ${allTokens[1]}`;
  } else {
    const initialTokens = allTokens.slice(0, allTokens.length - 1).join(', ');
    depositText = `${initialTokens} and ${allTokens[allTokens.length - 1]}`;
  }
  return depositText;
}

export const getMessagingBridgeName = (bridge: ArbitraryMessagingBridge): string | null => {
  switch (bridge) {
    case ArbitraryMessagingBridge.Wormhole:
      return 'Wormhole';
    case ArbitraryMessagingBridge.Polymer:
      return 'Polymer';
    case ArbitraryMessagingBridge.Mock:
      return 'Catalyst Mock';
    default:
      return null;
  }
};

export const getPoolTypeText = (type: CreatePoolType) => {
  if (type === CreatePoolType.CLASSIC) {
    return 'Classic';
  }
  if (type === CreatePoolType.STABLESWAP) {
    return 'Stable';
  }
  return '';
};
